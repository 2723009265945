import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import { campaignConfigurationSelectorFactory } from 'selectors/campaign';
import {
  steppedEntityLabelFormatterSelector,
  tagColorFormatterSelector,
  tagLabelFormatterSelector,
} from 'selectors/entities';

import {
  AnnotationTagCell,
  AnnotationTextCell,
} from 'components/ui/cells/AnnotationCell';
import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import {
  DateTimeCell,
  DayCell,
  LabelCell,
  ReactTableTextCell,
  ReactTableVolumeCell,
} from 'components/ui/table/cells/ReactTableCell';

import { longDayFormatter } from 'utils/formatter';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const polarizedTagSortMethod =
  (getEntityId, valueFormatter) => (aItem, bItem) => {
    const aValue = valueFormatter(getEntityId(aItem.original));
    const bValue = valueFormatter(getEntityId(bItem.original));
    if (bValue === null) return 1;
    if (aValue === null) return -1;
    if (aValue === bValue) return 0;
    return aValue > bValue ? 1 : -1;
  };

export const tableHeaderFactory = (name, help) =>
  function TableHeader() {
    return (
      <ReactTableHeader small helpMessage={help}>
        {capitalize(name)}
      </ReactTableHeader>
    );
  };

export const useIdentificationForm = 'identificationFormFields';
export const useUrlForm = 'urlFormFields';
export const useAnnotationForm = 'annotationFormFields';
export const useSatisfactionTag = 'satisfactionTagField';
export const useFeedbackForm = 'feedbackFields';

const TAG_FIELD_CONFIGURATION = {
  [useIdentificationForm]: {
    campaignFormFieldName: 'identification_form',
    feedbackFormValuesFieldName: 'respondent_values',
    many: true,
  },
  [useUrlForm]: {
    campaignFormFieldName: 'url_form',
    feedbackFormValuesFieldName: 'url_values',
    many: true,
  },
  [useAnnotationForm]: {
    campaignFormFieldName: 'annotation_form',
    feedbackFormValuesFieldName: 'annotation_values',
    many: true,
  },
  [useSatisfactionTag]: {
    campaignFormFieldName: 'satisfaction_tag',
    feedbackFormValuesFieldName: 'satisfaction_tag',
    many: false,
  },
};
const getEmptyCellProps = () => ({});

export const enrichFieldProps = (
  field,
  withTableProps,
  { isDateTime = false, isDate = false, isVolume = false } = {}
) => ({
  ...field,
  id: field.id || field.key || field.accessor,
  centered: field.centered || false,
  accessor:
    typeof field.accessor === 'string'
      ? (item) => item[field.accessor]
      : field.accessor,
  width: field.width || svars.defaultColumnMinWidth,
  maxWidth:
    field.maxWidth ||
    (isVolume && svars.defaultVolumeColumnMaxWidth) ||
    svars.defaultColumnMaxWidth,
  getCellProps: field.getCellProps || getEmptyCellProps,
  formatter: field.formatter || ((isDate || isDateTime) && longDayFormatter),
  Cell:
    field.Cell ||
    (isDate && DayCell) ||
    (isDateTime && DateTimeCell) ||
    (isVolume && ReactTableVolumeCell) ||
    ReactTableTextCell,
  ...(withTableProps
    ? {
        Header: field.Header || tableHeaderFactory(field.label)(),
      }
    : { header: field.label }),
});

/**
 *
 *
 * @param {*} getEntityId
 * @param {*} { id, label, tag_set, width }
 * @param {*} withTableProps
 * @param {*} tagColorFormatter
 * @param {*} tagLabelFormatter
 * @param {*} feedbackFormValuesFieldName
 * @param {boolean} [isEditableTag=false]
 * @return {*}
 */
export const createEntityField = (
  getEntityId,
  { id, label, tag_set, width, maxWidth, centered },
  withTableProps,
  colorFormatter,
  labelFormatter,
  valueFormatter,
  feedbackFormValuesFieldName,
  isEditableTag = false
) =>
  enrichFieldProps(
    {
      id,
      key: `entfield-${id}`,
      tagSetId: tag_set?.id,
      label,
      Cell: isEditableTag ? AnnotationTagCell : LabelCell,
      sortType: valueFormatter
        ? polarizedTagSortMethod(getEntityId, valueFormatter)
        : 'basic',

      width,
      maxWidth,
      centered: centered || true,
      getCellProps: (item) => ({
        color: colorFormatter(getEntityId(item)),
      }),
      accessor: (item) => labelFormatter(getEntityId(item)),
      feedbackFormValuesFieldName,
    },
    withTableProps
  );

const identity = (id) => id;

const getFormElementValuesAccessorFactory =
  (feedbackFormValuesFieldName, formField, callback = identity) =>
  (item) =>
    callback(
      item[feedbackFormValuesFieldName]?.find(
        ({ form_element: { id: formId } }) => formId === formField.id
      )
    );

/**
 * Tag campaign fields generator hook.
 * @param {*} param0
 * @param {*} withTableProps
 * @returns
 */
export function useCampaignFormFields(
  {
    useIdentificationForm: identificationForm,
    useUrlForm: urlForm,
    useAnnotationForm: annotationForm,
    useSatisfactionTag: satisfactionTag,
  },
  withTableProps,
  isEditableForm,
  isTagOnly = false
) {
  const { campaignId } = useParams();
  const campaignConfiguration = useSelector(
    campaignConfigurationSelectorFactory(campaignId)
  );

  const tagLabelFormatter = useSelector(tagLabelFormatterSelector);
  const tagColorFormatter = useSelector(tagColorFormatterSelector);

  const fields = useMemo(() => {
    const selectedFields = {};
    // Optionally add satisfaction tag field
    if (satisfactionTag) {
      const { campaignFormFieldName, feedbackFormValuesFieldName } =
        TAG_FIELD_CONFIGURATION[satisfactionTag];
      [selectedFields[satisfactionTag]] = [
        createEntityField(
          (item) => item[campaignFormFieldName]?.id,
          {
            id: '123',
            label: t`satisfaction-level`,
            tag_set: campaignConfiguration.satisfaction_tag_set,
            width: 200,
          },
          withTableProps,
          tagColorFormatter,
          tagLabelFormatter,
          null,
          feedbackFormValuesFieldName,
          false
        ),
      ];
    }
    // Add form tags fields
    [identificationForm, urlForm, annotationForm].forEach((formFlag) => {
      if (!formFlag) return;
      const { campaignFormFieldName, feedbackFormValuesFieldName } =
        TAG_FIELD_CONFIGURATION[formFlag];
      selectedFields[formFlag] = campaignConfiguration[campaignFormFieldName]
        .filter(
          ({ form_type }) =>
            !isTagOnly || form_type === 'MultipleChoiceFormElement'
        )
        .map(({ form_type, ...formField }) => {
          if (form_type === 'MultipleChoiceFormElement') {
            return createEntityField(
              getFormElementValuesAccessorFactory(
                feedbackFormValuesFieldName,
                formField,
                (itemValue) => itemValue?.tag?.id
              ),
              formField,
              withTableProps,
              tagColorFormatter,
              tagLabelFormatter,
              null,
              feedbackFormValuesFieldName,
              formFlag === useAnnotationForm && isEditableForm
            );
          }
          // form_type is 'InputFormElement'
          const { id, label, width, maxWidth, centered } = formField;
          return enrichFieldProps(
            {
              id,
              key: `textfield-${id}`,
              label,
              Cell:
                (formFlag === useAnnotationForm &&
                  isEditableForm &&
                  AnnotationTextCell) ||
                LabelCell,
              sortType: 'basic',
              width,
              maxWidth,
              centered: centered || true,
              getCellProps: () => ({}),
              accessor: getFormElementValuesAccessorFactory(
                feedbackFormValuesFieldName,
                formField,
                (itemValue) => itemValue?.value
              ),
              feedbackFormValuesFieldName,
            },
            withTableProps
          );
        });
    });

    return selectedFields;
  }, [campaignConfiguration]);
  return fields;
}
useCampaignFormFields.defaultProps = { tags: {} };

function useCampaignFields(
  withTableProps = false,
  flatten = true,
  isEditableForm = false,
) {
  const { campaignId } = useParams();
  const campaignConfiguration = useSelector(
    campaignConfigurationSelectorFactory(campaignId)
  );
  const steppedEntityLabelFormatter = useSelector(
    steppedEntityLabelFormatterSelector
  );
  const conceptLabelFormatter = steppedEntityLabelFormatter('concept');
  const {
    identificationFormFields,
    urlFormFields,
    annotationFormFields,
    satisfactionTagField,
  } = useCampaignFormFields(
    {
      useIdentificationForm,
      useUrlForm,
      useAnnotationForm,
      useSatisfactionTag,
    },
    withTableProps,
    isEditableForm
  );

  const fields = useMemo(() => {
    const fieldsMap = {
      feedbackFields: [
        enrichFieldProps(
          {
            key: 'date',
            label: t`date`,
            width: 60,
            accessor: (item) => item.date,
          },
          withTableProps,
          { isDateTime: true }
        ),
        enrichFieldProps(
          {
            key: 'concept',
            label: t`category`,
            accessor: (item) => conceptLabelFormatter(item.concept?.id),
            width: 80,
            centered: false,
          },
          withTableProps
        ),
      ],
      texts: [
        enrichFieldProps(
          {
            key: 'text',
            label: withTableProps ? t`text` : null,
            accessor: (item) => item.text || '-',
            width: 250,
          },
          withTableProps
        ),
      ],
      satisfactionTagField,
      identificationFormFields,
      urlFormFields,
      annotationFormFields,
    };
    return flatten
      ? Object.values(fieldsMap).flatMap((item) => item)
      : fieldsMap;
  }, [campaignConfiguration]);
  return fields;
}

export default useCampaignFields;
