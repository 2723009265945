import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider, Grid } from 'semantic-ui-react';

import { maybeFetchProductHierarchyGroups as onMaybeFetchProductHierarchyGroups } from 'actions/entity_groups';
import {
  createViewFacet,
  maybeFetchViewFacets as onMaybeFetchViewFacets,
  updateViewFacet,
} from 'actions/facet';
import { maybeFetchViews as onMaybeFetchViews } from 'actions/view';
import { languageSelector } from 'reducers/locale';
import {
  productHierarchyGroupsItemsSelector,
  restitutionLanguagesItemsSelector,
  sourceGroupsItemsSelector,
} from 'selectors/entities';
import { viewFacetByIdSelector } from 'selectors/facet';
import { analysisLanguageSelector } from 'selectors/user';
import { currentViewSelector } from 'selectors/view';

import ProductHierarchyGroupCreateModal from 'components/customer/home/ProductHierarchyGroupCreateModal';
import PERIOD_TYPES from 'components/customer/periodTypes';
import * as helpMessages from 'components/customer/v2/helpMessageV2';
import Header, { LargeHeader } from 'components/ui/Header';
import Link from 'components/ui/Link';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import LargeRadioButtonGroup from 'components/ui/button/LargeRadioButtonGroup';
import FlagItem from 'components/ui/cells/FlagItem';
import { SelectBox } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import CheckboxList from 'components/ui/inputs/checkbox-list';
import { ButtonLineLayout, PageLayout } from 'components/ui/layout/Page';
import SvgContentUrl from 'components/ui/svg/undraw_content_vbqo.svg';

import * as svars from 'assets/style/variables';

import FacetCreateField from './FacetCreateField';
import PeriodFieldHelper from './PeriodField';

const ALTERNATIVE_LANGUAGE_ITEMS = [
  {
    help: t`restitution-language.default-language-help`,
    type: false,
    header: t`default-language`,
  },
  {
    help: t`restitution-language.alternative-language-help`,
    type: true,
    header: t`alternative-language`,
  },
];

function HeaderLabel({ headerText, subHeaderText }) {
  return (
    <label
      htmlFor="lcb-ca"
      style={{ display: 'inline-flex', alignItems: 'center' }}
    >
      <Header style={{ margin: 0 }}>
        <Trans id={headerText} />
        <Header.Subheader>
          <Trans id={subHeaderText} />
        </Header.Subheader>
      </Header>
    </label>
  );
}

HeaderLabel.propTypes = {
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string.isRequired,
};

const findSourceGroup = (data) => (group) => group.value === data.value;

function ViewFacetCreatePage() {
  const { viewFacetId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // @TODO use id only remove deprecated group related fields
  const [baseProductHierarchyGroupId, setBaseProductHierarchyGroupId] =
    useState('');

  const currentView = useSelector(currentViewSelector);
  const [name, setName] = useState('');
  const [sourceGroupId, setSourceGroupId] = useState('');
  const [sourceGroupName, setSourceGroupName] = useState('');
  const [periodType, setPeriodType] = useState(null);
  const [period, setPeriod] = useState(null);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [useRestitutionLanguage, setUseRestitutionLanguage] = useState(false);
  const [restitutionLanguage, setRestitutionLanguage] = useState(undefined);
  const [
    comparativeProductHierarchyGroupIds,
    setComparativeProductHierarchyGroupIds,
  ] = useState([]);
  // Product hierarchy group creation modal management - we use one modal for both cases
  const [displayProductGroupCreateModal, setDisplayProductGroupCreateModal] =
    useState(false);
  const [createComparativeHierarchyGroup, setCreateComparativeHierarchyGroup] =
    useState(false);

  const isViewFacetUpdate = pathname.includes('update');
  const viewFacetToUpdateOrCopy = useSelector(
    viewFacetByIdSelector(viewFacetId)
  );
  const productHierarchyGroupsItems = useSelector(
    productHierarchyGroupsItemsSelector()
  );
  const comparativeHierarchyGroupsItems = useSelector(
    productHierarchyGroupsItemsSelector(baseProductHierarchyGroupId)
  );
  const sourceGroupsItems = useSelector(sourceGroupsItemsSelector);
  const restitutionLanguages = useSelector(restitutionLanguagesItemsSelector);
  const analysisLanguage = useSelector(analysisLanguageSelector);
  const appLanguage = useSelector(languageSelector);
  const preferredRestitutionLanguage =
    restitutionLanguages?.find(
      ({ value: restitutionValue }) => restitutionValue === appLanguage?.value
    )?.value || undefined;

  const onSelectUseRestitutionLanguage = (value) => {
    setUseRestitutionLanguage(value);
    if (value && preferredRestitutionLanguage) {
      setRestitutionLanguage(preferredRestitutionLanguage);
    } else if (!value) {
      setRestitutionLanguage(undefined);
    }
  };

  const checkFacetParameters = useCallback(() => {
    if (!viewFacetToUpdateOrCopy) {
      // In case of creation, check only the default restitution language
      if (preferredRestitutionLanguage) {
        setUseRestitutionLanguage(true);
        setRestitutionLanguage(preferredRestitutionLanguage);
      }
      return;
    }
    if (isViewFacetUpdate) {
      setName(viewFacetToUpdateOrCopy.name);
    } else {
      // Copy - we change the name of the facet to show it is a copy
      setName(`${t`copy-of`} ${viewFacetToUpdateOrCopy.name}`);
    }
    setSourceGroupId(viewFacetToUpdateOrCopy.source_group.id);
    setSourceGroupName(viewFacetToUpdateOrCopy.source_group.name);
    setBaseProductHierarchyGroupId(
      viewFacetToUpdateOrCopy.base_product_hierarchy_group.id
    );
    setPeriodType(viewFacetToUpdateOrCopy.period_type);
    if (viewFacetToUpdateOrCopy.period_type === 'custom') {
      if (viewFacetToUpdateOrCopy.min_date) {
        setMinDate(new Date(viewFacetToUpdateOrCopy.min_date));
      }
      if (viewFacetToUpdateOrCopy.max_date) {
        setMaxDate(new Date(viewFacetToUpdateOrCopy.max_date));
      }
    } else {
      setPeriod(viewFacetToUpdateOrCopy.period);
    }
    if (
      viewFacetToUpdateOrCopy.comparative_product_hierarchy_groups &&
      viewFacetToUpdateOrCopy.comparative_product_hierarchy_groups.length
    ) {
      setComparativeProductHierarchyGroupIds(
        viewFacetToUpdateOrCopy.comparative_product_hierarchy_groups.map(
          ({ id }) => id
        )
      );
    }
    if (viewFacetToUpdateOrCopy.restitution_language) {
      setUseRestitutionLanguage(true);
      setRestitutionLanguage(viewFacetToUpdateOrCopy.restitution_language);
    }
  }, [viewFacetToUpdateOrCopy, isViewFacetUpdate, appLanguage]);

  useEffect(() => {
    dispatch(onMaybeFetchViews());
    dispatch(onMaybeFetchViewFacets());
    dispatch(onMaybeFetchProductHierarchyGroups());
    checkFacetParameters();
  }, []);
  useEffect(() => {
    checkFacetParameters();
  }, [viewFacetToUpdateOrCopy]);

  const onModalClose = useCallback(
    (newGroupId) => {
      if (displayProductGroupCreateModal) {
        setDisplayProductGroupCreateModal(false);
        if (newGroupId) setBaseProductHierarchyGroupId(newGroupId);
      } else if (createComparativeHierarchyGroup) {
        setCreateComparativeHierarchyGroup(false);
        if (newGroupId) {
          setComparativeProductHierarchyGroupIds([
            ...comparativeProductHierarchyGroupIds,
            newGroupId,
          ]);
        }
      }
    },
    [
      displayProductGroupCreateModal,
      createComparativeHierarchyGroup,
      comparativeProductHierarchyGroupIds,
    ]
  );

  const onPeriodChange = (newPeriod) => () => setPeriod(newPeriod);

  const onSelectSourceGroup = (e, data) => {
    if (data.value) {
      const sourceGroupOption = data.options.find(findSourceGroup(data));
      setSourceGroupId(data.value);
      setSourceGroupName(sourceGroupOption.text);
    } else {
      // Element was deleted, removing from state
      setSourceGroupId('');
      setSourceGroupName('');
    }
  };

  const onSelectRestitutionLanguage = (e, { value }) =>
    setRestitutionLanguage(value);

  const onSelectPeriodType = useCallback(
    (value) => {
      setPeriodType(value);
      setPeriod(value ? PERIOD_TYPES?.[value].periods[0]?.value : null);

      if (minDate) {
        setMinDate(null);
      }
      if (maxDate) {
        setMaxDate(null);
      }
    },
    [minDate, maxDate]
  );

  const onSelectBaseProductHierarchyGroup = useCallback(
    (e, { value }) => {
      setBaseProductHierarchyGroupId(value);

      if (comparativeProductHierarchyGroupIds.includes(value)) {
        setComparativeProductHierarchyGroupIds(
          comparativeProductHierarchyGroupIds.filter((id) => id !== value)
        );
      }
    },
    [comparativeProductHierarchyGroupIds]
  );
  const goToViewFacetList = () => navigate('/facets');

  const validateUpdate = () => {
    dispatch(
      updateViewFacet({
        viewFacetId,
        name,
        sourceGroupId,
        baseProductHierarchyGroupId,
        comparativeProductHierarchyGroupIds,
        periodType,
        period,
        minDate,
        maxDate,
        restitutionLanguage,
      })
    );
    navigate('/facets');
  };
  const validateCreate = () => {
    dispatch(
      createViewFacet({
        name,
        sourceGroupId,
        baseProductHierarchyGroupId,
        comparativeProductHierarchyGroupIds,
        periodType,
        period,
        minDate,
        maxDate,
        restitutionLanguage,
      })
    );
    navigate('/facets');
  };

  let title;
  let description;
  if (viewFacetId && isViewFacetUpdate) {
    title = t`Modifier l'analyse "${viewFacetToUpdateOrCopy?.name}"`;
    description = helpMessages.analysisUpdateDescription;
  } else {
    title = t`create-new-analysis`;
    description = helpMessages.analysisCreateDescription;
  }
  const gaLabel = `(${sourceGroupName}) ${baseProductHierarchyGroupId} `;
  const disabled =
    baseProductHierarchyGroupId === '' ||
    sourceGroupId === '' ||
    name === '' ||
    (useRestitutionLanguage && !restitutionLanguage) ||
    (maxDate && minDate && maxDate < minDate);
  if (!currentView || !currentView.configuration) return null;
  return (
    <PageLayout simple>
      <div style={{ padding: '1rem', overflowY: 'auto' }}>
        <Grid style={{ padding: svars.spaceMedium }}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={9}>
              <LargeHeader>{title}</LargeHeader>
              <div style={{ maxWidth: svars.textMaxWidth }}>
                {description.map((item) => (
                  <p key={`text-it-${item.id || item}`}>
                    <Trans id={item} />
                  </p>
                ))}
              </div>
            </Grid.Column>
            <Grid.Column
              width={7}
              floated="right"
              textAlign="right"
              verticalAlign="bottom"
            >
              <img
                style={{
                  maxHeight: '15rem',
                  paddingRight: svars.spaceLarge,
                }}
                src={SvgContentUrl}
                alt="star"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed>
          <FacetCreateField
            input={
              <LimitedTextInput
                style={{
                  width: '100%',
                  fontWeight: svars.fontWeightBold,
                  fontSize: svars.fontSizeXLarge,
                }}
                placeholder={t`facet-create.new-analysis-title`}
                value={name}
                onChange={(e, data) => setName(data.value)}
                maxCharacters={80}
              />
            }
            inputWidth={15}
          />
          <FacetCreateField
            name={t`base-product-services-group`}
            input={
              <SelectBox
                placeholder={t`product-services-group`}
                options={productHierarchyGroupsItems}
                onChange={onSelectBaseProductHierarchyGroup}
                value={baseProductHierarchyGroupId}
              />
            }
            legend={
              <Link
                base
                onClick={() => setDisplayProductGroupCreateModal(true)}
              >
                <Trans id="create-new-product-group" />
              </Link>
            }
            helpMessage={
              helpMessages.analysisBaseProductHierarchyGroupDescription
            }
          />
          <FacetCreateField
            name={t`source(s)`}
            input={
              <SelectBox
                placeholder={t`source(s)`}
                options={sourceGroupsItems}
                onChange={onSelectSourceGroup}
                value={sourceGroupId}
              />
            }
            helpMessage={helpMessages.analysisSourceGroupDescription}
          />
          {restitutionLanguages?.length ? (
            <FacetCreateField
              name={
                <HeaderLabel
                  headerText={t`restitution-language`}
                  subHeaderText={t`restitution-language.form-subtitle`}
                />
              }
              input={
                <LargeRadioButtonGroup
                  items={ALTERNATIVE_LANGUAGE_ITEMS}
                  value={useRestitutionLanguage}
                  onClick={onSelectUseRestitutionLanguage}
                />
              }
              helpMessage={
                <>
                  <div style={{ margin: `${svars.spaceMedium} 0` }}>
                    <Trans id="this-analysis-use-restitution-language" />{' '}
                  </div>

                  {useRestitutionLanguage ? (
                    <SelectBox
                      style={{
                        maxWidth: svars.smallInputMaxWidth,
                      }}
                      placeholder={t`select-a-restitution-language`}
                      options={restitutionLanguages}
                      onChange={onSelectRestitutionLanguage}
                      value={restitutionLanguage}
                    />
                  ) : (
                    <SelectBox
                      style={{
                        pointerEvents: 'none',
                        opacity: 1,
                        maxWidth: svars.smallInputMaxWidth,
                      }}
                      value="1"
                      clearable={false}
                      icon={null}
                      options={[
                        {
                          value: '1',
                          key: '1',
                          text: (
                            <FlagItem
                              style={{
                                display: 'inline',
                              }}
                              language={analysisLanguage}
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                </>
              }
            />
          ) : null}
          <FacetCreateField
            name={t`period`}
            input={
              <LargeRadioButtonGroup
                items={Object.values(PERIOD_TYPES)}
                value={periodType}
                onClick={onSelectPeriodType}
              />
            }
            inputWidth={7}
            helpMessage={
              <PeriodFieldHelper
                period={period}
                periodType={periodType}
                periodLengths={
                  periodType ? PERIOD_TYPES[periodType]?.periods : []
                }
                minDate={minDate}
                maxDate={maxDate}
                onPeriodChange={onPeriodChange}
                onChangeStartPeriodDatePicker={setMinDate}
                onChangeEndPeriodDatePicker={setMaxDate}
              />
            }
          />
          <FacetCreateField
            name={
              <HeaderLabel
                headerText={t`comparative-analysis`}
                subHeaderText={t`comparative-analysis.form-subtitle`}
              />
            }
            input={
              <CheckboxList
                style={{ height: '270px' }}
                items={comparativeHierarchyGroupsItems}
                onSelectItem={({ value }) =>
                  setComparativeProductHierarchyGroupIds([
                    ...(comparativeProductHierarchyGroupIds || []),
                    value,
                  ])
                }
                onUnselectItem={({ value }) =>
                  setComparativeProductHierarchyGroupIds(
                    (comparativeProductHierarchyGroupIds || []).filter(
                      (itemValue) => itemValue !== value
                    )
                  )
                }
                onResetItems={() => setComparativeProductHierarchyGroupIds([])}
                placeholder={t`comparative-product-services-groups`}
                selectedItems={comparativeProductHierarchyGroupIds}
                searchable={comparativeHierarchyGroupsItems.length > 6}
                nMaxSelectedItems={svars.nMaxComparativeGroups}
                noDataMessage={t`no-existing-group`}
              />
            }
            legend={
              <Link
                base
                onClick={() => setCreateComparativeHierarchyGroup(true)}
              >
                <Trans id="create-new-product-group" />
              </Link>
            }
            helpMessage={
              helpMessages.analysisComparativeProductHierarchyGroupDescription
            }
          />
        </Grid>
      </div>
      <Divider style={{ margin: 0 }} />
      <ButtonLineLayout padded>
        <AnalyticsAwareButton
          gaCategory="V2"
          gaAction="Update View Facet"
          gaLabel="cancel"
          inputComponent={ButtonTransparentAccent}
          onClick={goToViewFacetList}
        >
          <Trans id="cancel" />
        </AnalyticsAwareButton>
        {viewFacetId && isViewFacetUpdate ? (
          <AnalyticsAwareButton
            gaCategory="V2"
            gaAction="Update View Facet"
            gaLabel={gaLabel}
            inputComponent={ButtonAccent}
            onClick={validateUpdate}
            disabled={disabled}
            style={{ marginLeft: svars.spaceMediumLarge }}
          >
            <Trans id="modify" />
          </AnalyticsAwareButton>
        ) : (
          <AnalyticsAwareButton
            gaCategory="V2"
            gaAction="Create View Facet"
            gaLabel={gaLabel}
            inputComponent={ButtonAccent}
            onClick={validateCreate}
            disabled={disabled}
            style={{ marginLeft: svars.spaceMediumLarge }}
          >
            <Trans id="create" />
          </AnalyticsAwareButton>
        )}
      </ButtonLineLayout>
      <ProductHierarchyGroupCreateModal
        open={displayProductGroupCreateModal || createComparativeHierarchyGroup}
        key={`product-hierarchy-group-create-modal-${displayProductGroupCreateModal}-${createComparativeHierarchyGroup}`}
        onClose={onModalClose}
      />
    </PageLayout>
  );
}

export default ViewFacetCreatePage;
