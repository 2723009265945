import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import { ButtonAccent, ButtonSecondaryDanger } from 'components/ui/button';

import {
  addEventListeners,
  removeEventListeners,
} from './util/eventListenerUtil';

export default function TimeoutLogic({
  logOutMethod,
  automaticLogOutMethod,
  firstDelay,
  secondDelay,
}) {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
      }, firstDelay);

    const createTimeout2 = () =>
      setTimeout(() => {
        // Implement a sign out function here
        automaticLogOutMethod();
      }, secondDelay);

    const listener = () => {
      if (!isWarningModalOpen) {
        // eslint-disable-next-line no-use-before-define
        clearTimeout(timeout);
        // eslint-disable-next-line no-use-before-define
        timeout = createTimeout1();
      }
    };

    // Initialization
    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);
  return (
    <div>
      <Modal
        closeIcon
        open={isWarningModalOpen}
        onClose={() => setWarningModalOpen(false)}
      >
        <Modal.Header content="Session Timeout" />
        <Modal.Content>
          You&apos;re being timed out due to inactivity. Please choose to
          continue to customize or quit. Otherwise, you will be logged off
          automatically
        </Modal.Content>
        <Modal.Actions>
          <ButtonSecondaryDanger type="submit" onClick={logOutMethod}>
            Quit
          </ButtonSecondaryDanger>
          <ButtonAccent
            type="submit"
            onClick={() => setWarningModalOpen(false)}
          >
            Continue
          </ButtonAccent>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

TimeoutLogic.propTypes = {
  logOutMethod: PropTypes.func.isRequired,
  automaticLogOutMethod: PropTypes.func.isRequired,
  firstDelay: PropTypes.number.isRequired,
  secondDelay: PropTypes.number.isRequired,
};
