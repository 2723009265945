import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  actionTypes as campaignActionTypes,
  deleteCampaign,
  maybeGetCampaigns,
} from 'actions/campaign';
import { loadingStateSelector } from 'reducers/ui';
import { campaignsSelector } from 'selectors/campaign';
import { isAdminSelector } from 'selectors/user';

import { enrichFieldProps } from 'components/customer/campaign/useCampaignFields';
import Link from 'components/ui/Link';
import ManagementList from 'components/ui/ManagementList';
import ManagementListRowFactory from 'components/ui/ManagementList/ManagementListRowFactory';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { PageLayout } from 'components/ui/layout/Page';
import DeleteModal from 'components/ui/modal/DeleteModal';
import { CampaignStatusCell } from 'components/ui/table/cells/dotCells';

import getCampaignSubRoutes from './campaignSubRoutes';

const emptyCampaignListContentFactory = (createCampaign) => {
  function EmptyListActions({ resetFilters }) {
    return (
      <Trans>
        <Link base onClick={createCampaign}>
          Créer une campagne
        </Link>{' '}
        ou{' '}
        <Link base onClick={resetFilters}>
          réinitialiser les filtres
        </Link>{' '}
        pour afficher toutes les campagnes.
      </Trans>
    );
  }

  EmptyListActions.propTypes = { resetFilters: PropTypes.func.isRequired };
  return EmptyListActions;
};

export function CampaignListLoader() {
  const dispatch = useDispatch();
  useEffect(() => dispatch(maybeGetCampaigns()), []);
  return null;
}

const getRowActions = (onNavigateTo, isAdmin) =>
  getCampaignSubRoutes(isAdmin).map(({ to, ctaText, icon }) => ({
    help: ctaText,
    icon,
    onClick: onNavigateTo(to),
    isCopyToClipboard: false,
  }));

function CampaignListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [toDeleteCampaignName, setToDeleteCampaignName] = useState('');
  const [toDeleteCampaignId, setTopDeleteCampaignId] = useState('');
  const campaigns = useSelector(campaignsSelector);
  const isAdmin = useSelector(isAdminSelector);

  const campaignsIsLoading = useSelector(
    loadingStateSelector([campaignActionTypes.FETCH_CAMPAIGNS_LIST_REQUEST])
  );

  const onNavigateTo = useCallback(
    (campaignPage) =>
      ({ id }) =>
        navigate(campaignPage ? `./${id}/${campaignPage}` : `./${id}`),
    []
  );

  const onDelete = useCallback(({ name, id }) => {
    setToDeleteCampaignName(name);
    setTopDeleteCampaignId(id);
    setModalOpen(true);
  }, []);
  const onCampaignCreate = useCallback(() => navigate('./create'), []);
  const closeModal = useCallback(() => setModalOpen(false), []);
  const onDeleteCampaign = useCallback(
    () => dispatch(deleteCampaign(toDeleteCampaignId)),
    [toDeleteCampaignId]
  );
  const rowActions = getRowActions(onNavigateTo, isAdmin);
  const rowFields = [
    enrichFieldProps(
      {
        key: 'name',
        accessor: 'name',
        label: t`name`,
      },
      false
    ),
    enrichFieldProps(
      {
        key: 'create_date',
        accessor: 'create_date',
        label: t`creation-date`,
        width: 100,
      },
      false,
      { isDateTime: true }
    ),
    enrichFieldProps(
      {
        key: 'status',
        accessor: 'status',
        label: t`status`,
        width: 60,
        Cell: CampaignStatusCell,
      },
      false
    ),
  ];
  return (
    <PageLayout simple padded>
      <ManagementList
        nActions={4}
        testid="bo-campaign-search-bar"
        rowFields={rowFields}
        onRowClick={(item) => navigate(`./${item.id}`)}
        loading={campaignsIsLoading}
        items={campaigns}
        defaultSorted={{
          key: 'create_date',
          ascending: false,
        }}
        renderItemRow={ManagementListRowFactory(onDelete, rowActions, isAdmin)}
        textFilterPlaceholder={t`search-a-campaign`}
        emptyListHeader={<Trans id="no-campaign" />}
        EmptyListContent={emptyCampaignListContentFactory(onCampaignCreate)}
        actions={
          <AnalyticsAwareButton
            gaCategory="Campaign management"
            gaAction="Create campaign"
            inputComponent={ButtonAccent}
            onClick={onCampaignCreate}
            content={t`create-new-campaign`}
            icon="plus"
            labelPosition="right"
            disabled={!isAdmin}
            data-testid="bo-campaign-list-create-campaign-button"
          />
        }
      />
      <DeleteModal
        open={modalOpen}
        onClose={closeModal}
        onDelete={onDeleteCampaign}
        headerText={t`campaign-deletion.confirm-modal-title`}
        contentText={
          <Trans>
            campaign-deletion.confirm-modal-message-
            <b>{toDeleteCampaignName}</b>
          </Trans>
        }
        contentSubText={
          <Trans>
            campaign-deletion.confirm-modal-sub-message-
            <DeleteModal.WarningText>delete-forever</DeleteModal.WarningText>
          </Trans>
        }
        confirmWithInputValue={toDeleteCampaignName}
      />
    </PageLayout>
  );
}

CampaignListPage.propTypes = {};

export default CampaignListPage;
