import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Divider, Icon } from 'semantic-ui-react';

import {
  removeFaqItem,
  reorderFaqItemsFromDragAndDrop,
  resetAllFaqItemsToDefault,
  restoreFaqItem,
  saveCampaignConfigurationAsDraft,
} from 'actions/campaign';
import {
  faqItemsSelectorFactory,
  isFaqItemsEmptySelectorFactory,
} from 'selectors/campaign';

import { DraggableElementFactory } from 'components/ui/ManagementList/DraggableElement';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import FeedbackAppFaqItemModal from 'components/ui/inputs/FeedbackAppFaqItemModal';
import DragAndDropList from 'components/ui/layout/DragAndDropList';

import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

function CustomizeFaqItems() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [creationActivated, setCreationActivated] = useState(false);
  const [editingActivated, setEditingActivated] = useState(false);
  const [selectedFaqItem, setSelectedFaqItem] = useState(null);
  const isFaqItemsEmpty = useMemoizedFactorySelector(
    isFaqItemsEmptySelectorFactory,
    campaignId
  );
  const faqItems = useMemoizedFactorySelector(
    faqItemsSelectorFactory,
    campaignId
  );
  const onResetAllFaqItemsToDefault = useCallback(() => {
    dispatch(resetAllFaqItemsToDefault(campaignId));
  }, [campaignId]);
  const onReorderFaqItemsFromDragAndDrop = useCallback(
    (newOrderList) =>
      dispatch(reorderFaqItemsFromDragAndDrop(campaignId, newOrderList)),
    []
  );
  const onEditFaqItem = useCallback(async (faqItem) => {
    await setSelectedFaqItem(faqItem);
    setEditingActivated(true);
  }, []);
  const onDeactivateEditing = useCallback(() => setEditingActivated(false), []);
  const onActivateCreation = useCallback(() => setCreationActivated(true), []);
  const onDeactivateCreation = useCallback(() => {
    setCreationActivated(false);
    dispatch(saveCampaignConfigurationAsDraft(campaignId));
  }, [campaignId]);
  const onRestoreFaqItem = useCallback(
    (item) => dispatch(restoreFaqItem(campaignId, item.id)),
    []
  );
  const onRemoveFaqItem = useCallback(
    (item) => dispatch(removeFaqItem(campaignId, item.id)),
    []
  );
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SecondaryTabButton onClick={onActivateCreation}>
          <Trans id="add-faq-section" />
        </SecondaryTabButton>
        <SecondaryTabButton
          onClick={onResetAllFaqItemsToDefault}
          style={{ marginLeft: svars.spaceMedium }}
        >
          <Trans id="reset-faq-sections-to-default" />
        </SecondaryTabButton>
      </div>
      {isFaqItemsEmpty && (
        <span style={{ color: svars.colorDanger }}>
          <Icon name="exclamation" />
          <Trans id="a-campaign-must-have-one-faq" />
        </span>
      )}
      <Divider />
      <DragAndDropList
        elements={faqItems}
        onChange={onReorderFaqItemsFromDragAndDrop}
        renderElementSegment={DraggableElementFactory(
          onEditFaqItem,
          onRestoreFaqItem,
          onRemoveFaqItem,
          t`edit-faq-section`,
          t`restore-faq-section`,
          t`remove-faq-section`
        )}
      />
      <FeedbackAppFaqItemModal
        campaignId={campaignId}
        create
        open={creationActivated}
        onClose={onDeactivateCreation}
      />
      <FeedbackAppFaqItemModal
        campaignId={campaignId}
        faqItem={selectedFaqItem}
        open={editingActivated}
        onClose={onDeactivateEditing}
      />
    </>
  );
}

CustomizeFaqItems.propTypes = {};

export default CustomizeFaqItems;
