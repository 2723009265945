import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  addIdentificationFormElement,
  removeIdentificationFormElement,
  reorderIdentificationFormFromDragAndDrop,
  resetWholeIdentificationFormToDefault,
  restoreIdentificationFormElement,
  saveCampaignConfigurationAsDraft,
  setExpandedForm,
  updateIdentificationFormElement,
} from 'actions/campaign';
import {
  campaignConfigurationIdentificationFormDeletionSelectorFactory,
  expandedFormSelectorFactory,
  onGenerateTagSetsOptionsSelector,
} from 'selectors/campaign';

import FormItemsManagementList from 'components/ui/ManagementList/FormItemsManagementList';
import Segment from 'components/ui/Segment';

import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import CustomizePageWording from './CustomizePageWording';
import TogglableSection from './TogglableSection';

const IDENTIFICATION_ON_FORM_FIELD_NAME = 'identification_form';

function CustomizeIdentificationForm({ page, inputLabels }) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  const identificationForm = useMemoizedFactorySelector(
    campaignConfigurationIdentificationFormDeletionSelectorFactory,
    campaignId,
    IDENTIFICATION_ON_FORM_FIELD_NAME,
    true
  );

  const tagSetsOptions = useSelector(onGenerateTagSetsOptionsSelector);
  const expandedForm = useSelector(expandedFormSelectorFactory(campaignId));

  const endCreation = useCallback(() => {
    dispatch(saveCampaignConfigurationAsDraft(campaignId));
  }, []);
  const onRestoreIdentificationFormElement = useCallback(
    (element) =>
      dispatch(restoreIdentificationFormElement(campaignId, element.id)),
    []
  );
  const onRemoveIdentificationFormElement = useCallback(
    (element) =>
      dispatch(removeIdentificationFormElement(campaignId, element.id)),
    []
  );
  const onResetWholeIdentificationFormToDefault = useCallback(
    () => dispatch(resetWholeIdentificationFormToDefault(campaignId)),
    []
  );

  const onReorderIdentificationFormFromDragAndDrop = useCallback(
    (newOrderList) =>
      dispatch(
        reorderIdentificationFormFromDragAndDrop(campaignId)(newOrderList)
      ),
    []
  );
  const onUpdateIdentificationFormElement = useCallback(
    (element) => dispatch(updateIdentificationFormElement(campaignId, element)),
    []
  );
  const onAddIdentificationFormElement = useCallback(
    (element) => dispatch(addIdentificationFormElement(campaignId, element)),
    []
  );
  const onToggleExpandedForm = useCallback(
    () => dispatch(setExpandedForm(campaignId, !expandedForm)),
    [campaignId, expandedForm]
  );
  return (
    <div>
      <TogglableSection
        checked={expandedForm}
        onChange={onToggleExpandedForm}
        title={<Trans id="always-expand-identification-form" />}
        subtitle={<Trans id="always-expand-identification-form-help" />}
        style={{ marginBottom: svars.spaceNormalLarge }}
      />
      <CustomizePageWording
        campaignId={campaignId}
        page={page}
        inputLabels={inputLabels}
      />
      <Segment>
        <FormItemsManagementList
          items={identificationForm}
          addItemLabel={t`add-identification-form-element`}
          editItemLabel={t`edit-identification-form-element`}
          restoreItemLabel={t`restore-identification-form-element`}
          removeItemLabel={t`remove-identification-form-element`}
          resetAllLabel={t`reset-identification-form-element`}
          emptyListHeader={t`identification-form.element-list-empty-header`}
          emptyListMessage={t`identification-form.element-list-empty-message`}
          onAddElement={onAddIdentificationFormElement}
          onUpdateElement={onUpdateIdentificationFormElement}
          onRestoreElement={onRestoreIdentificationFormElement}
          onRemoveElement={onRemoveIdentificationFormElement}
          onReorderFromDragAndDrop={onReorderIdentificationFormFromDragAndDrop}
          onResetToDefault={onResetWholeIdentificationFormToDefault}
          onCreateCallback={endCreation}
          tagSetsOptions={tagSetsOptions}
          mandatoryIsAllowed
        />
      </Segment>
    </div>
  );
}

CustomizeIdentificationForm.propTypes = {
  page: PropTypes.string.isRequired,
  inputLabels: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CustomizeIdentificationForm;
