import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { useChannelLabelFormatter } from 'reducers/entityLabelFormatter';
import { campaignConfigurationSelectorFactory } from 'selectors/campaign';
import {
  steppedEntityLabelFormatterSelector,
  tagColorFormatterSelector,
  tagValueFormatterSelector,
} from 'selectors/entities';
import { loadingSelector } from 'store/monitor/monitorSlice';
import useGetCampaignExportTitle from 'store/monitor/useGetCampaignExportTitle';

import {
  createEntityField,
  enrichFieldProps,
} from 'components/customer/campaign/useCampaignFields';
import Dashboard from 'components/ui/dashboard/Dashboard';
import { reactTableVolumeCellFactory } from 'components/ui/table/cells/ReactTableCell';
import { CSATCell } from 'components/ui/table/cells/dotCells';

import { getCSATScoreColor } from 'utils/colors';
import {
  dayFormatter,
  incrementFormatterFactory,
  monthFormatter,
  numberFormatter,
  numberOnHundredFormatter,
  weekFormatter,
  zeroPrecisionPercentFormatter,
} from 'utils/formatter';
import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import AlternativeDistribution, {
  useAlternativeDistributionOptions,
} from './chart/AlternativeDistribution';
import Distribution from './chart/Distribution';
import HorizontalBar from './chart/HorizontalBar';
import Kpi, { kpiAccessorFactory } from './chart/Kpi';
import TimeSeries from './chart/TimeSeries';

// Use a method to make sure the translation is not evaluated at import time
const GET_FREQUENCY_OPTIONS = () => [
  { key: 'D', value: 'D', i18nText: t`day` },
  { key: 'W', value: 'W', i18nText: t`week` },
  { key: 'M', value: 'M', i18nText: t`month` },
];

const makeVolumeColumn = ({ data, meta }) =>
  enrichFieldProps(
    {
      id: meta.indicatorKey,
      label: meta.indicatorLabel,
      accessor: meta.indicatorKey,
      centered: true,
      Cell: reactTableVolumeCellFactory(
        Math.max(...(data || []).map((item) => item[meta.indicatorKey]))
      ),
    },
    true,
    { isVolume: true }
  );

const getMonitoringDashboardConfig = (
  campaignConfiguration,
  steppedLabelFormatter,
  tagColorFormatter,
  tagValueFormatter,
  channelLabelFormatter
) => {
  const tagLabelFormatter = steppedLabelFormatter('tag');
  return [
    {
      row: 0,
      height: 3,
      children: [
        {
          row: 0,
          col: 0,
          width: 3,
          children: [
            {
              row: 0,
              col: '0-0',
              height: 1.5,
              hideMenu: true,
              component: Kpi,
              title: t`Volume`,
              accessor: kpiAccessorFactory('volume'),
              meta: {
                formatter: numberFormatter,
                increaseFormatter: incrementFormatterFactory(
                  zeroPrecisionPercentFormatter
                ),
              },
            },
            {
              row: 0,
              col: '0-1',
              height: 1.5,
              hideMenu: true,
              component: Kpi,
              title: t`CSAT-score`,
              titleHelperHeader: t`CSAT-score-long`,
              titleHelper: t`csat-score-helper`,
              accessor: kpiAccessorFactory('score'),
              meta: {
                color: getCSATScoreColor,
                formatter: numberOnHundredFormatter,
                increaseFormatter: incrementFormatterFactory(
                  zeroPrecisionPercentFormatter
                ),
              },
            },
          ],
        },
        {
          width: 7,
          row: 0,
          col: 1,
          title: i18n._(t`contributions-over-time`),
          component: TimeSeries,
          accessor: (data) => data.ts,
          meta: {
            cellKey: 'date',
            cellLabel: t`Date`,
            indicatorKey: 'volume',
            indicatorLabel: t`Volume`,
            lineIndicatorKey: 'score',
            lineIndicatorLabel: t`CSAT-score`,
            //
            dataAlternativeOptions: GET_FREQUENCY_OPTIONS(),
            alternativeValueNodeId: 'time-series-select',
            getDefaultChartProps: (data) => ({
              data: (data && Object.values(data)[0]) || null,
              dateFormatter: dayFormatter,
            }),
            getChartProps: (data, { value }) =>
              value
                ? {
                    data: data[value],
                    dateFormatter:
                      (value === 'M' && monthFormatter) ||
                      (value === 'W' && weekFormatter) ||
                      dayFormatter,
                  }
                : {},
          },
          makeTableData: ({ data, meta }) => ({
            data,
            columns: [
              enrichFieldProps(
                {
                  id: meta.cellKey,
                  label: meta.cellLabel,
                  accessor: meta.cellKey,
                },
                true,
                { isDate: true }
              ),
              makeVolumeColumn({ data, meta }),
              enrichFieldProps(
                {
                  id: 'score',
                  label: t`CSAT-score`,
                  accessor: 'score',
                  Cell: CSATCell,
                  centered: true,
                },
                true
              ),
            ],
          }),
        },
        {
          row: 0,
          col: 2,
          width: 5,
          title: t`campaign-channels`,
          accessor: (data) => data.distributions?.channel,
          component: Distribution,
          meta: {
            cellKey: 'channel',
            cellLabel: t`distribution-channel`,
            indicatorKey: 'volume',
            indicatorLabel: t`volume`,
            minColor: svars.minVolumeColor,
            maxColor: svars.volumeStrokeColor,
          },
          formatters: {
            labelFormatter: channelLabelFormatter,
          },
          makeTableData: ({ data, meta }) => ({
            data,
            defaultSorted: [{ id: meta.cellKey, desc: true }],
            columns: [
              createEntityField(
                (item) => item[meta.cellKey],
                {
                  id: meta.cellKey,
                  label: meta.cellLabel,
                  accessor: meta.cellKey,
                },
                true,
                null,
                channelLabelFormatter,
                null,
                null,
                false
              ),
              makeVolumeColumn({ data, meta }),
            ],
          }),
        },
      ],
    },
    {
      row: 1,
      height: 3,
      children: [
        {
          row: 1,
          col: 0,
          width: 6,
          title: t`satisfaction-level`,
          accessor: (data) => data.satisfaction_tag_distribution,
          component: Distribution,
          meta: {
            cellKey: 'satisfaction_tag',
            cellLabel: t`satisfaction-level`,
            indicatorKey: 'volume',
            indicatorLabel: t`volume`,
          },
          formatters: {
            labelFormatter: tagLabelFormatter,
            colorFormatter: tagColorFormatter,
          },
          makeTableData: ({ data, meta }) => ({
            data,
            defaultSorted: [{ id: meta.cellKey, desc: true }],
            columns: [
              createEntityField(
                (item) => item[meta.cellKey],
                {
                  id: meta.cellKey,
                  label: meta.cellLabel,
                  accessor: meta.cellKey,
                  tag_set: campaignConfiguration.satisfaction_tag_set,
                },
                true,
                tagColorFormatter,
                tagLabelFormatter,
                tagValueFormatter,
                null,
                false
              ),
              makeVolumeColumn({ data, meta }),
            ],
          }),
        },
        {
          row: 1,
          col: 1,
          width: 6,
          title: t`distribution-over-categories-top-15`,
          component: HorizontalBar,
          accessor: (data) => data.distributions?.concept,
          meta: {
            cellKey: 'concept',
            cellLabel: t`concept`,
            entityKey: 'concept',
            indicatorKey: 'volume',
            indicatorLabel: t`volume`,
          },
          makeTableData: ({ data, meta }) => ({
            data,
            defaultSorted: [{ id: meta.indicatorKey, desc: true }],
            columns: [
              createEntityField(
                (item) => item[meta.cellKey],
                {
                  id: meta.cellKey,
                  label: meta.cellLabel,
                  accessor: meta.cellKey,
                  width: 1.5 * svars.defaultColumnMinWidth,
                  maxWidth: 1.5 * svars.defaultColumnMaxWidth,
                  centered: false,
                },
                true,
                tagColorFormatter,
                steppedLabelFormatter('concept'),
                null,
                null,
                false
              ),
              makeVolumeColumn({ data, meta }),
            ],
          }),
          formatters: {
            labelFormatter: steppedLabelFormatter('concept'),
            colorFormatter: tagColorFormatter,
          },
        },

        {
          row: 1,
          col: 2,
          width: 6,
          title: t`distribution-by`,
          component: AlternativeDistribution,
          accessor: (data) => data.tag_distributions,
          meta: {
            cellKey: null,
            cellLabel: t`tag`,
            indicatorKey: 'volume',
            indicatorLabel: t`volume`,
            // Meta properties for alternative charts
            dataAlternativeOptions: useAlternativeDistributionOptions,
            alternativeValueNodeId: 'distribution-select',
            getDefaultChartProps: (data, { options }, meta) => {
              const defaultFormFieldId = (data && Object.keys(data)[0]) || null;
              return {
                data: defaultFormFieldId ? data[defaultFormFieldId] : null,
                meta: {
                  ...meta,
                  cellKey: options.find(
                    (option) => option.value === defaultFormFieldId
                  )?.tagSetId,
                },
              };
            },
            getChartProps: (data, { value, options }, meta) =>
              value
                ? {
                    data: data[value],
                    meta: {
                      ...meta,
                      cellKey: options.find((option) => option.value === value)
                        ?.tagSetId,
                    },
                  }
                : {},
          },
          formatters: {
            labelFormatter: (value) => tagLabelFormatter(value),
            colorFormatter: tagColorFormatter,
          },
          makeTableData: ({ data, meta }) => ({
            data,
            columns: [
              createEntityField(
                (item) => item[meta.cellKey],
                {
                  id: meta.cellKey,
                  label: steppedLabelFormatter('tagSet')(meta.cellKey),
                  accessor: meta.cellKey,
                },
                true,
                tagColorFormatter,
                tagLabelFormatter,
                null,
                null,
                false
              ),
              makeVolumeColumn({ data, meta }),
            ],
          }),
        },
      ],
    },
  ];
};

function MonitoringDashboard() {
  const { campaignId } = useParams();

  const loading = useSelector(loadingSelector);
  const tagColorFormatter = useSelector(tagColorFormatterSelector);
  const tagValueFormatter = useSelector(tagValueFormatterSelector);
  const channelLabelFormatter = useChannelLabelFormatter(campaignId)();
  const campaignConfiguration = useMemoizedFactorySelector(
    campaignConfigurationSelectorFactory,
    campaignId
  );
  const getExportTitle = useGetCampaignExportTitle(campaignId);
  const steppedEntityLabelFormatter = useSelector(
    steppedEntityLabelFormatterSelector
  );
  const elements = getMonitoringDashboardConfig(
    campaignConfiguration,
    steppedEntityLabelFormatter,
    tagColorFormatter,
    tagValueFormatter,
    channelLabelFormatter
  );
  return (
    <Dashboard
      loading={loading}
      elements={elements}
      getExportTitle={getExportTitle}
    />
  );
}

export default MonitoringDashboard;
