import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from 'components/ui/Header';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const Container = styled.label`
  padding: ${svars.spaceMediumLarge} ${svars.spaceMedium};
  display: inline-flex;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  background: ${svars.accentColorLightest};
  border-radius: ${svars.ctaBorderRadius};
`;

function TogglableSection({
  title,
  subtitle,
  checked,
  onChange,
  style,
  gaCategory,
  gaAction,
  gaLabel,
}) {
  return (
    <Container style={style}>
      <Header
        style={{
          marginBottom: svars.spaceNormal,
          marginRight: svars.spaceNormalLarge,
        }}
      >
        {title}
        <Header.Subheader style={{ marginTop: svars.spaceNormal }}>
          {subtitle}
        </Header.Subheader>
      </Header>
      <AnalyticsAwareCheckbox
        gaCategory={gaCategory}
        gaAction={gaAction}
        gaLabel={gaLabel}
        checked={checked}
        onChange={onChange}
        toggle
        style={{ minWidth: '4rem' }}
      />
    </Container>
  );
}

TogglableSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  gaCategory: PropTypes.string.isRequired,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  style: commonPropTypes.style,
};

TogglableSection.defaultProps = {
  gaAction: null,
  gaLabel: null,
  style: {},
};

export default TogglableSection;
