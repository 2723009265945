import React, { useMemo } from 'react';

import Distribution from 'components/customer/campaign/monitor/chart/Distribution';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import {
  useAnnotationForm,
  useCampaignFormFields,
  useIdentificationForm,
  useUrlForm,
} from '../../useCampaignFields';

export const useAlternativeDistributionOptions = () => {
  const formFields = useCampaignFormFields(
    {
      useIdentificationForm,
      useUrlForm,
      useAnnotationForm,
    },
    false,
    false,
    true
  );
  const options = useMemo(() =>
    Object.values(formFields)
      .flatMap((item) => item)
      .map(({ label, id, tagSetId }) => ({
        key: id,
        value: id,
        text: label,
        tagSetId,
      }))
  );
  return options;
};

function AlternativeDistribution({
  chartId,
  height,
  width,
  data,
  meta: { cellKey, indicatorKey, minColor, maxColor },
  formatters,
}) {
  const distributionMeta = useMemo(
    () => ({
      cellKey,
      indicatorKey,
      minColor: minColor || svars.minVolumeColor,
      maxColor: maxColor || svars.volumeStrokeColor,
    }),
    [cellKey, indicatorKey, minColor, maxColor]
  );
  return (
    <Distribution
      chartId={chartId}
      height={height - 25}
      width={width}
      data={data}
      meta={distributionMeta}
      formatters={formatters}
    />
  );
}

AlternativeDistribution.propTypes = {
  ...commonPropTypes.dashboardChart,
  data: commonPropTypes.dashboardChart.data,
};
AlternativeDistribution.defaultProps = {
  data: [],
};
AlternativeDistribution.minHeight = svars.chartHeight;
AlternativeDistribution.minWidth = svars.chartMinWidth;

export default AlternativeDistribution;
