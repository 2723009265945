import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';

import {
  feedbackByIdSelector,
  focusFeedbackIdSelector,
  focusOnNextFeedback,
  setFocusFeedbackId,
} from 'store/monitor/monitorSearchSlice';

import { FORMS_ICON_MAP } from 'components/customer/campaign/campaignSubRoutes';
import PaneFields from 'components/ui/panels/PaneFields';
import SidePane from 'components/ui/panels/SidePane';

import * as svars from 'assets/style/variables';

import useCampaignFields, {
  useAnnotationForm,
  useFeedbackForm,
  useIdentificationForm,
  useSatisfactionTag,
  useUrlForm,
} from '../useCampaignFields';

function FeedbackPane() {
  const dispatch = useDispatch();
  const focusFeedbackId = useSelector(focusFeedbackIdSelector);
  const feedback = useSelector(feedbackByIdSelector(focusFeedbackId));
  const fieldsMap = useCampaignFields(false, false, true);

  const onToggle = useCallback(() => {
    if (focusFeedbackId) dispatch(setFocusFeedbackId(null));
  }, [focusFeedbackId]);
  const onFocusPreviousFeedback = useCallback(() => {
    if (focusFeedbackId) dispatch(focusOnNextFeedback(false));
  }, [focusFeedbackId]);
  const onFocusNextFeedback = useCallback(() => {
    if (focusFeedbackId) dispatch(focusOnNextFeedback(true));
  }, [focusFeedbackId]);
  return (
    <SidePane
      onToggle={onToggle}
      animation="push"
      direction="right"
      visible={focusFeedbackId !== null}
      width="very wide"
      dimmed={0}
      dimmerColor={svars.colorLightestGrey}
    >
      <SidePane.Header
        title={t`response-details`}
        onToggle={onToggle}
        onSelectPrevious={onFocusPreviousFeedback}
        onSelectNext={onFocusNextFeedback}
        onSelectPreviousHelp={t`previous-response`}
        onSelectNextHelp={t`next-response`}
        gaCategory="Campaign - feedback panel"
      />
      <SidePane.Body>
        {feedback && focusFeedbackId ? (
          <>
            <PaneFields
              fields={fieldsMap[useFeedbackForm]}
              feedback={feedback}
            />
            <PaneFields
              fields={[fieldsMap[useSatisfactionTag]]}
              feedback={feedback}
            />
            <PaneFields
              header={t`texts`}
              headerIcon="quote right"
              fields={fieldsMap.texts}
              feedback={feedback}
            />
            <PaneFields
              header={t`campaign-channel`}
              headerIcon={FORMS_ICON_MAP.urlFormFields?.icon}
              fields={fieldsMap[useUrlForm]}
              feedback={feedback}
            />
            <PaneFields
              header={t`respondent-identification`}
              headerIcon={FORMS_ICON_MAP.identificationFormFields?.icon}
              fields={fieldsMap[useIdentificationForm]}
              feedback={feedback}
            />
            <PaneFields
              alwaysShow
              header={t`annotation`}
              headerIcon={FORMS_ICON_MAP.annotationFormFields?.icon}
              fields={fieldsMap[useAnnotationForm]}
              feedback={feedback}
            />
          </>
        ) : null}
      </SidePane.Body>
    </SidePane>
  );
}
FeedbackPane.propTypes = {};
export default FeedbackPane;
