import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';

import {
  deactivateRestoreDiff,
  deletePreviewConfig,
  saveCampaignConfiguration,
  saveCampaignConfigurationAsDraft,
} from 'actions/campaign';
import { isFaqItemsEmptySelectorFactory } from 'selectors/campaign';

import {
  ButtonAccent,
  ButtonSecondaryAccent,
  ButtonSecondaryDanger,
} from 'components/ui/button/index';

import { useMemoizedFactorySelector } from 'utils/hooks';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

function SaveControl({ campaignId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFaqItemsEmpty = useMemoizedFactorySelector(
    isFaqItemsEmptySelectorFactory,
    campaignId
  );
  const goToCampaignList = useCallback(() => navigate('/campaign'), []);
  const [quitModalActivated, setQuitModalActivated] = useState(false);
  const onGeneratePreviewConfig = useCallback(
    () => dispatch(saveCampaignConfigurationAsDraft(campaignId)),
    [campaignId]
  );
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: `${svars.spaceNormal} 0px`,
      }}
    >
      <div>
        <ButtonSecondaryAccent
          style={{ margin: svars.spaceMedium }}
          disabled={isFaqItemsEmpty}
          onClick={onGeneratePreviewConfig}
        >
          <Icon name="save" />
          <Trans id="save-draft" />
        </ButtonSecondaryAccent>
        <ButtonAccent
          disabled={isFaqItemsEmpty}
          style={{ margin: svars.spaceMedium }}
          onClick={async () => {
            await dispatch(saveCampaignConfiguration(campaignId));
            dispatch(deactivateRestoreDiff());
          }}
        >
          <Icon name="send" />
          <Trans render={capitalizedTranslation} id="publish-campaign" />
        </ButtonAccent>
      </div>
      <Modal
        closeIcon
        open={quitModalActivated}
        onClose={() => setQuitModalActivated(false)}
      >
        <Modal.Header>
          <span>
            <Trans id="confirm-quit" />
            {/* Are you sure you want to quit ? */}
          </span>
        </Modal.Header>
        <Modal.Content>
          <span>
            <Trans id="unsaved-changes-confirm-quit-customization" />
            You have unsaved changes are you sure you want to quit the
            customization ?
          </span>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ButtonSecondaryAccent onClick={() => setQuitModalActivated(false)}>
              <Trans render={capitalizedTranslation} id="cancel" />
            </ButtonSecondaryAccent>
            <div>
              <ButtonSecondaryAccent
                disabled={isFaqItemsEmpty}
                onClick={() => {
                  dispatch(saveCampaignConfigurationAsDraft(campaignId));
                  setQuitModalActivated(false);
                  goToCampaignList();
                }}
              >
                <Trans id="save-draft-then-quit" />
                {/* Save draft and quit */}
              </ButtonSecondaryAccent>
              <ButtonSecondaryDanger
                onClick={() => {
                  dispatch(deletePreviewConfig(campaignId));
                  setQuitModalActivated(false);
                  goToCampaignList();
                }}
              >
                <Trans id="delete-changes-then-quit" />
                Delete changes and quit
              </ButtonSecondaryDanger>
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

SaveControl.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default SaveControl;
