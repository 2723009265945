import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import {
  formSelectorFactory,
  tagSetDropdownSelectorFactory,
} from 'selectors/campaign';
import {
  createChannel,
  createChannelFormValuesSelectorFactory,
  setFormValue,
} from 'store/monitor/channelsSlice';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import Header from 'components/ui/Header';
import MandatoryField from 'components/ui/MandatoryField';
import { MediumPaddedSegment } from 'components/ui/Segment';
import { ButtonAccent, ButtonTransparentAccent } from 'components/ui/button';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput, TextInput } from 'components/ui/inputs/TextInput';

import * as svars from 'assets/style/variables';

function CreateChannelModal({ open, onClose }) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const urlForm = useSelector(formSelectorFactory(campaignId, 'url_form'));
  const formValues = useSelector(createChannelFormValuesSelectorFactory);
  const tagSets = {};
  urlForm.forEach((item) => {
    if (item.tag_set) {
      tagSets[item.tag_set.id] = useSelector(
        tagSetDropdownSelectorFactory(campaignId, item.tag_set.id)
      );
    }
  });

  const [channelName, setChannelName] = useState('');
  const onChannelNameChange = useCallback(
    (e, { value }) => setChannelName(value),
    []
  );

  const onTagFormValueChange = useCallback(
    (formId, tagSetId) =>
      (e, { value }) =>
        dispatch(
          setFormValue({ key: formId, value: { tagId: value, tagSetId } })
        ),
    []
  );
  const onBasicFormValueChange = useCallback(
    (formId) =>
      (e, { value }) =>
        dispatch(setFormValue({ key: formId, value })),
    []
  );
  const onConfirm = useCallback(() => {
    dispatch(createChannel({ campaignId, channelName }));
    onClose();
  }, [campaignId, channelName]);
  const isComplete =
    !!channelName &&
    urlForm.every(
      ({ id, mandatory }) =>
        !mandatory ||
        (typeof formValues[id] === 'object' && formValues[id]?.tagId) ||
        (typeof formValues[id] === 'string' && formValues[id])
    );
  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header content={t`create-new-channel`} />
      <Modal.Content>
        <MediumPaddedSegment>
          {urlForm.length ? (
            urlForm.map(
              ({ id, label, tag_set, placeholder, form_type, mandatory }) => (
                <div
                  key={`${id}`}
                  style={{ paddingBottom: svars.spaceMediumLarge }}
                >
                  <Header>
                    {label}
                    <MandatoryField mandatory={mandatory} />
                  </Header>
                  {form_type === 'InputFormElement' ? (
                    <TextInput
                      value={formValues[id]}
                      onChange={onBasicFormValueChange(id)}
                      placeholder={placeholder}
                      fluid
                    />
                  ) : (
                    <StyledDropdown
                      search={
                        tagSets[tag_set.id] && tagSets[tag_set.id].length > 10
                      }
                      selection
                      clearable
                      options={tagSets[tag_set.id]}
                      disabled={!module}
                      value={formValues[id]?.tagId}
                      onChange={onTagFormValueChange(id, tag_set?.id)}
                      placeholder={placeholder}
                    />
                  )}
                </div>
              )
            )
          ) : (
            <EmptyDataPage
              maxHeight="100px"
              layout="row"
              i18nHeaderText={t`no-url-form-field`}
              style={{ margin: 'auto' }}
            />
          )}
        </MediumPaddedSegment>
        <MediumPaddedSegment style={{ display: 'inline-flex', width: '100%' }}>
          <LimitedTextInput
            style={{
              width: '100%',
              fontWeight: svars.fontWeightBold,
              fontSize: svars.fontSizeXLarge,
            }}
            placeholder={t`name`}
            value={channelName}
            onChange={onChannelNameChange}
            maxCharacters={20}
            data-testid="bo-campaign-channel-creation-name-input"
          />
        </MediumPaddedSegment>
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentAccent
          onClick={() => onClose()}
          content={t`cancel`}
          style={{ marginRight: svars.spaceLarge }}
          data-testid="bo-campaign-channel-creation-cancel-button"
        />
        <ButtonAccent
          disabled={!isComplete}
          onClick={onConfirm}
          content={t`create-channel`}
          data-testid="bo-campaign-channel-creation-create-button"
        />
      </Modal.Actions>
    </Modal>
  );
}

CreateChannelModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
CreateChannelModal.defaultProps = {
  open: false,
};

export default CreateChannelModal;
