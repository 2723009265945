import React from 'react';

import PropTypes from 'prop-types';

import { Bar, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { getHorizontalVolumeSvgGradient } from 'components/customer/visualization/SvgSentimentGradient';
import { BarChart } from 'components/ui/visualization/StyledChart';

import commonPropTypes from 'utils/commonPropTypes';
import { renderSvgTextSpans } from 'utils/svg';

import * as svars from 'assets/style/variables';

const BAR_AXIS_SPACE = 20;

const yAxisLabelTickFactory = (labelFormatter, tickLabelWidth) => {
  function YAxisLeftTick({ y, payload: { value } }) {
    return (
      <text y={y} textAnchor="end" dominantBaseline="middle">
        {renderSvgTextSpans(
          labelFormatter(value),
          tickLabelWidth + 50,
          2 * (tickLabelWidth + 50) + 40,
          y,
          y + 14,
          null,
          false,
          {},
          false,
          svars.svgCharWidthFontSizeSmall,
          10
        )}
      </text>
    );
  }
  YAxisLeftTick.propTypes = {
    y: PropTypes.number.isRequired,
    payload: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }).isRequired,
  };

  return YAxisLeftTick;
};

function CustomBarLabel({ x, y, width, value }) {
  return (
    <text
      style={{ fontSize: svars.fontSizeBase }}
      x={x + width}
      y={y}
      dy={svars.barSize / 2}
      dx={5}
      dominantBaseline="middle"
    >
      {value}
    </text>
  );
}
CustomBarLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};
CustomBarLabel.defaultProps = { value: null };

function HorizontalBar({
  chartId,
  height,
  width,
  data,
  meta: { cellKey, indicatorKey },
  formatters: { labelFormatter },
}) {
  const maxTextWidth = 20;
  const tickLabelWidth = 0.3 * width;
  return (
    <ResponsiveContainer
      width="100%"
      height={height}
      debounce={200}
      id={chartId}
    >
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          left: tickLabelWidth,
          right: maxTextWidth + (BAR_AXIS_SPACE - 8),
        }}
      >
        {getHorizontalVolumeSvgGradient()}
        <XAxis hide axisLine={false} type="number" domain={[0, 'maxValue']} />
        <YAxis
          ticks={data.map((item) => item[cellKey])}
          axisLine={{ stroke: svars.chartFontColor }}
          tickLine={{ stroke: svars.chartFontColor }}
          yAxisId={0}
          dataKey={cellKey}
          type="category"
          interval={0}
          tick={yAxisLabelTickFactory(labelFormatter, tickLabelWidth)}
        />
        <Bar
          dataKey={indicatorKey}
          barSize={svars.barSize}
          label={CustomBarLabel}
        >
          {data.map((d) => (
            <Cell
              radius={svars.barRadius}
              key={d[cellKey]}
              fill="url(#colorHorizontalBarVolume)"
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

HorizontalBar.propTypes = commonPropTypes.dashboardChart;
HorizontalBar.defaultProps = {
  data: [],
  meta: {},
};
HorizontalBar.minHeight = svars.chartHeight;
HorizontalBar.minWidth = svars.chartMinWidth;

export default HorizontalBar;
