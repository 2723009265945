import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Grid, Icon, Rating } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  useProductHierarchyGroupLabelFormatter,
  useSourceGroupLabelFormatter,
} from 'reducers/entityLabelFormatter';

import moment from 'moment';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import HelpTooltip from 'components/ui/HelpTooltip';
import Link, { RouterLinkV2 } from 'components/ui/Link';
import RowMenu from 'components/ui/ManagementList/RowMenu';
import { LightText } from 'components/ui/Text';
import {
  AnalyticsAwareButton,
  ButtonSecondaryAccent,
} from 'components/ui/button';
import FlagItem from 'components/ui/cells/FlagItem';
import { PrimaryLabel } from 'components/ui/inputs/Label';

import commonPropTypes from 'utils/commonPropTypes';
import { formatPeriod } from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const FacetDetails = styled.div`
  display: ${(props) => (props.active ? 'inherit' : 'none')};
  padding: ${svars.spaceLarge};
`;

const FavoriteIcon = styled(Rating)`
  &&& {
    i {
      outline: none;
    }
  }
`;

function ViewFacetDetails({ viewFacet }) {
  const productHierarchyGroupLabelFormatter =
    useProductHierarchyGroupLabelFormatter();
  const sourceGroupLabelFormatter = useSourceGroupLabelFormatter();

  return (
    <Grid style={{display: 'block'}}>
      <Grid.Row style={{ padding: '0' }}>
        <Grid.Column width={7} textAlign="right">
          <PrimaryLabel>
            <Trans
              render={capitalizedTranslation}
              id="base-product-services-group"
            />
          </PrimaryLabel>
        </Grid.Column>
        <Grid.Column width={9}>
          {productHierarchyGroupLabelFormatter(
            viewFacet.base_product_hierarchy_group?.id
          )}
        </Grid.Column>
      </Grid.Row>
      {viewFacet.comparative_product_hierarchy_groups &&
      viewFacet.comparative_product_hierarchy_groups.length ? (
        <Grid.Row style={{ padding: '0' }}>
          <Grid.Column width={7} textAlign="right">
            <PrimaryLabel>
              <Trans
                render={capitalizedTranslation}
                id="comparative-product-services-groups"
              />
            </PrimaryLabel>
          </Grid.Column>
          <Grid.Column width={9}>
            {viewFacet.comparative_product_hierarchy_groups
              ? viewFacet.comparative_product_hierarchy_groups
                  .map(({ id }) => productHierarchyGroupLabelFormatter(id))
                  .join(', ')
              : '-'}
          </Grid.Column>
        </Grid.Row>
      ) : null}
      <Grid.Row style={{ padding: '0' }}>
        <Grid.Column width={7} textAlign="right">
          <PrimaryLabel>
            <Icon name="newspaper outline" />
            <Trans render={capitalizedTranslation} id="source(s)" />
          </PrimaryLabel>
        </Grid.Column>
        <Grid.Column width={9}>
          {sourceGroupLabelFormatter(viewFacet?.source_group?.id)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: '0' }}>
        <Grid.Column width={7} textAlign="right">
          <PrimaryLabel>
            <Icon name="calendar alternate" />
            <Trans render={capitalizedTranslation} id="period" />
          </PrimaryLabel>
        </Grid.Column>
        <Grid.Column width={9}>
          {(viewFacet && !viewFacet.period_type && (
            <Trans render={capitalizedTranslation} id="all-time" />
          )) ||
            formatPeriod(viewFacet) ||
            null}
        </Grid.Column>
      </Grid.Row>
      {viewFacet?.restitution_language ? (
        <Grid.Row style={{ padding: '0' }}>
          <Grid.Column width={7} textAlign="right">
            <PrimaryLabel>
              {/* <Icon name="newspaper outline" /> */}
              <Trans
                render={capitalizedTranslation}
                id="restitution-language"
              />
            </PrimaryLabel>
          </Grid.Column>
          <Grid.Column width={9}>
            <FlagItem language={viewFacet?.restitution_language} />
          </Grid.Column>
        </Grid.Row>
      ) : null}
    </Grid>
  );
}
ViewFacetDetails.propTypes = {
  viewFacet: commonPropTypes.viewFacet.isRequired,
};

function NoFacetPage() {
  return (
    <EmptyDataPage
      headerText={<Trans id="no-analysis-found" />}
      actionComponent={
        <div>
          <Trans>
            Commencez par{' '}
            <RouterLinkV2 base="true" to="/facets/create">
              créer une analyse
            </RouterLinkV2>
            , ou naviguez vers{' '}
            <RouterLinkV2 base="true" to="/facets/product-groups">
              vos groupes de produit(s) / service(s)
            </RouterLinkV2>
            .
          </Trans>
        </div>
      }
    />
  );
}

NoFacetPage.propTypes = {};

export const viewFacetRowFactory = (
  showDetailsViewFacetIds,
  onViewFacetDelete,
  onSetViewFacetAsFavorite,
  onShowViewFacetDetails
) => {
  function ViewFacetRow({ item }) {
    const viewFacet = item;
    const lastUpdateDate = viewFacet.aggregates_update_date
      ? viewFacet.aggregates_update_date &&
        moment(viewFacet.aggregates_update_date)
      : null;
    const viewFacetIsReady = viewFacet.status === 'ready';
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column width={7} verticalAlign="middle">
              {viewFacet.name}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: svars.fontColorLightest,
                  marginRight: svars.spaceMedium,
                }}
              >
                <span>
                  <Trans id="created-on@fem." />
                  {` ${moment(viewFacet.create_date).format(svars.dateFormat)}`}
                </span>
                <span>
                  (
                  <Link base onClick={onShowViewFacetDetails(viewFacet.id)}>
                    <Trans
                      id={
                        showDetailsViewFacetIds.includes(viewFacet.id)
                          ? t`less-details`
                          : t`more-details`
                      }
                    />
                  </Link>
                  )
                </span>
              </div>
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle">
              <span style={{ display: 'inline-block', textAlign: 'start' }}>
                {lastUpdateDate
                  ? lastUpdateDate.format(svars.dateTimeDateFormat)
                  : '-'}
                <br />
                <span style={{ color: svars.fontColorLightest }}>
                  {lastUpdateDate
                    ? lastUpdateDate.format(svars.dateTimeTimeFormat)
                    : '-'}
                </span>
              </span>
            </Grid.Column>
            <Grid.Column width={1} textAlign="center" verticalAlign="middle">
              <HelpTooltip
                help={[
                  viewFacet.favorite
                    ? t`view-facet-list.remove-from-favorites`
                    : t`view-facet-list.add-to-favorites`,
                ]}
                compact
                position="top center"
                trigger={
                  <span>
                    <FavoriteIcon
                      size="huge"
                      icon="star"
                      rating={viewFacet.favorite ? 1 : 0}
                      onRate={onSetViewFacetAsFavorite(
                        viewFacet.id,
                        !viewFacet.favorite
                      )}
                      maxRating={1}
                      style={{ margin: `0 ${svars.spaceNormal}` }}
                    />
                  </span>
                }
              />
            </Grid.Column>
            <Grid.Column
              width={4}
              floated="right"
              textAlign="right"
              verticalAlign="middle"
            >
              {viewFacetIsReady ? (
                <RouterLink to={`/facets/analyze/${viewFacet.id}/overview`}>
                  <AnalyticsAwareButton
                    id="visualize"
                    inputComponent={ButtonSecondaryAccent}
                    gaCategory="View facet list"
                    gaAction="Go to view facet analysis"
                    gaLabel={viewFacet.id}
                    style={{ marginRight: svars.spaceMedium }}
                  >
                    <Trans id="visualize" />
                  </AnalyticsAwareButton>
                </RouterLink>
              ) : (
                <LightText style={{ marginRight: svars.spaceMedium }}>
                  <Trans
                    id={
                      viewFacet.status === 'computing'
                        ? t`analysis-running`
                        : t`analysis-waiting`
                    }
                  />
                </LightText>
              )}

              <RowMenu
                disabled={!viewFacetIsReady}
                items={[
                  {
                    to: `/facets/update/${viewFacet.id}`,
                    content: t`modify`,
                    iconColor: 'blue',
                    icon: 'setting',
                  },
                  {
                    to: `/facets/copy/${viewFacet.id}`,
                    content: t`copy`,
                    iconColor: 'blue',
                    icon: 'clone outline',
                  },
                  {
                    content: t`delete`,
                    icon: 'delete',
                    iconColor: 'red',
                    onClick: onViewFacetDelete(viewFacet),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <FacetDetails active={showDetailsViewFacetIds.includes(viewFacet.id)}>
          <ViewFacetDetails viewFacet={viewFacet} />
        </FacetDetails>
      </>
    );
  }
  ViewFacetRow.propTypes = {
    item: commonPropTypes.viewFacet.isRequired,
  };
  return ViewFacetRow;
};
export default viewFacetRowFactory;
