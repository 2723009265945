import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import {
  campaignChannelsSelector,
  deleteChannel,
  focusChannelSelector,
  loadingChannelsSelector,
  setFocusChannelId,
} from 'store/monitor/channelsSlice';

import {
  enrichFieldProps,
  useCampaignFormFields,
  useUrlForm,
} from 'components/customer/campaign/useCampaignFields';
import Link from 'components/ui/Link';
import ManagementList from 'components/ui/ManagementList';
import ManagementListRowFactory from 'components/ui/ManagementList/ManagementListRowFactory';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { PageLayout } from 'components/ui/layout/Page';
import DeleteModal from 'components/ui/modal/DeleteModal';
import { ActivityIndicatorCell } from 'components/ui/table/cells/dotCells';

import config from 'config';
import { useMemoizedFactorySelector } from 'utils/hooks';

import ChannelPane, { QRCodeModal } from './ChannelPane';
import CreateChannelModal from './CreateChannelModal';

const formatFeedbackAppRoute = (item) =>
  item ? `${config.PREVIEW_URL}${item?.app_route}` : null;

const emptyChannelListContentFactory = (createCampaign) => {
  function EmptyListActions() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <Trans id="channel-list.no-channel-message" />
        <br />
        <br />
        <Trans>
          <Link primary base onClick={createCampaign}>
            Create a channel
          </Link>{' '}
          to start linking your feedback application.
        </Trans>
      </div>
    );
  }

  EmptyListActions.propTypes = {};
  return EmptyListActions;
};

function CampaignChannelListPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [toDeleteChannel, setToDeleteChannel] = useState(null);
  const [qrCodeModalChannel, setQrCodeModalChannel] = useState(null);
  const channels = useMemoizedFactorySelector(
    campaignChannelsSelector,
    campaignId
  );
  const channelsAreLoading = useSelector(loadingChannelsSelector);
  const focusChannel = useMemoizedFactorySelector(
    focusChannelSelector,
    campaignId
  );
  const { urlFormFields: fields } = useCampaignFormFields(
    { useUrlForm },
    false
  );

  const toggleQrCodeModal = useCallback(
    (item) => setQrCodeModalChannel(item || null),
    [qrCodeModalChannel]
  );
  const onOpenCreateModal = useCallback(() => setCreateModalOpen(true), []);
  const onCloseCreateModal = useCallback(() => setCreateModalOpen(false), []);
  const onCloseDeleteModal = useCallback(() => setToDeleteChannel(null), []);
  const onQrCodeModalClose = useCallback(() => setQrCodeModalChannel(null), []);
  const onValidateDelete = useCallback(() => {
    dispatch(deleteChannel({ campaignId, channelId: toDeleteChannel.id }));
    setToDeleteChannel(null);
  }, [campaignId, toDeleteChannel]);
  const onFocusChannel = useCallback(
    ({ id }) => dispatch(setFocusChannelId(id)),
    []
  );
  const rowFields = [
    enrichFieldProps(
      {
        accessor: 'name',
        label: t`name`,
      },
      false
    ),
    ...fields,
    enrichFieldProps(
      {
        accessor: 'create_date',
        label: t`creation-date`,
        width: 110,
      },
      false,
      { isDate: true }
    ),
    enrichFieldProps(
      {
        accessor: 'n_feedback',
        sortType: 'basic',
        label: t`responses`,
        Cell: ActivityIndicatorCell,
        width: 80,
        centered: true,
      },
      false
    ),
  ];
  const renderItemRow = useMemo(
    () =>
      ManagementListRowFactory(setToDeleteChannel, [
        {
          help: t`copy-application-link-to-clipboard`,
          icon: 'linkify',
          onClick: null,
          isCopyToClipboard: true,
          getCopyableText: formatFeedbackAppRoute,
        },
        {
          help: t`display-qrcode`,
          icon: 'qrcode',
          onClick: toggleQrCodeModal,
          isCopyToClipboard: false,
        },
      ]),
    [toggleQrCodeModal]
  );
  return (
    <PageLayout simple padded>
      <ManagementList
        testid="bo-channel-search-bar"
        onRowClick={onFocusChannel}
        loading={channelsAreLoading}
        items={channels}
        defaultSorted={{
          key: 'create_date',
          ascending: false,
        }}
        rowFields={rowFields}
        nActions={2}
        renderItemRow={renderItemRow}
        textFilterPlaceholder={t`search-a-channel`}
        emptyListHeader={<Trans id="channel-list.no-channel-title" />}
        EmptyListContent={emptyChannelListContentFactory(onOpenCreateModal)}
        actions={
          <AnalyticsAwareButton
            gaCategory="Campaign management"
            gaAction="Create channel"
            icon="add"
            labelPosition="right"
            inputComponent={ButtonAccent}
            onClick={onOpenCreateModal}
            content={t`create-channel`}
            data-testid="bo-create-channel-button"
          />
        }
      />
      {createModalOpen ? (
        <CreateChannelModal
          open={createModalOpen}
          onClose={onCloseCreateModal}
        />
      ) : null}
      <DeleteModal
        open={toDeleteChannel !== null}
        onClose={onCloseDeleteModal}
        onDelete={onValidateDelete}
        headerText={t`channel-deletion.confirm-modal-title`}
        contentText={
          <Trans>
            channel-deletion.confirm-modal-message-
            <b>{toDeleteChannel?.name}</b>
          </Trans>
        }
        contentSubText={
          <Trans>
            channel-deletion.confirm-modal-sub-message-
            <DeleteModal.WarningText>delete-forever</DeleteModal.WarningText>
          </Trans>
        }
        confirmWithInputValue={toDeleteChannel?.name}
        // confirmWithInputMessage={t`channel-deletion.confirm-with-input-message`}
      />
      <ChannelPane channel={focusChannel} />
      <QRCodeModal
        onClose={onQrCodeModalClose}
        open={!!qrCodeModalChannel}
        value={formatFeedbackAppRoute(qrCodeModalChannel)}
        channelName={qrCodeModalChannel?.name}
      />
    </PageLayout>
  );
}

CampaignChannelListPage.propTypes = {};

export default CampaignChannelListPage;
