import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Segment, Tab } from 'semantic-ui-react';

import {
  closePreview,
  deactivateRestoreDiff,
  deletePreviewConfig,
  openPreview,
  restoreCachedDiff,
  saveCampaignConfigurationAsDraft,
} from 'actions/campaign';
import {
  campaignConfigurationIsLoadingSelectorFactory,
  hasUnsavedChangesSelector,
  previewActivatedSelector,
  restorationActivatedSelector,
} from 'selectors/campaign';
import { conceptsSelector } from 'selectors/entities';

import TimeoutLogic from 'components/customer/inactivity-timeout/TimeoutLogic';
import { StyledTabPane } from 'components/ui/Tab';
import {
  ButtonSecondaryAccent,
  ButtonSecondaryDanger,
  ButtonTransparentAccent,
} from 'components/ui/button';

import config from 'config';
import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import CustomizeFaqItems from './CustomizeFaqItems';
import CustomizeIdentificationForm from './CustomizeIdentificationForm';
import CustomizePageWording from './CustomizePageWording';
import GeneralCampaignCustomizeTab from './GeneralCampaignCustomizeTab';
import SaveControl from './SaveControl';

const reloadIframe = () => {
  document.getElementById('preview_iframe').src += '';
};

const getCustomizationPanes = (campaignId, firstGeneralFeedbackConceptId) => [
  {
    menuItem: t`general`,
    render: () => <GeneralCampaignCustomizeTab />,
    pageRoute: '',
  },
  {
    menuItem: t`home-page`,
    render: () => (
      <StyledTabPane>
        <CustomizePageWording
          campaignId={campaignId}
          page="home"
          inputLabels={{
            title: t`home-title`,
            subtitle: t`home-subtitle`,
            secondSubtitle: t`home-second-subtitle`,
            ctaText: t`home-cta-button`,
            consultFAQPrefix: t`you-have-questions?`,
            consultFAQ: t`check-faq`,
          }}
        />
      </StyledTabPane>
    ),
    pageRoute: '',
  },
  {
    menuItem: t`search-page`,
    render: () => (
      <StyledTabPane>
        <CustomizePageWording
          campaignId={campaignId}
          page="search"
          inputLabels={{
            keyword_examples: t`keyword-examples`, // 'Examples de mots-clés',
            title: t`search-page-title`, // 'Title',
            inputPlaceholder: t`input-placeholder`, // 'Barre de recherche',
            noResults: t`no-results`, // 'Aucun résultat',
            leaveQuickFeedback: t`leave-quick-feedback`, // 'Laisser un avis rapide',
            clearSearch: t`clear-search`, // 'Effectuer une nouvelle recherche',
            searchAConcept: t`search-a-concept`, // 'Recherchez un sujet sur lequel contribuer',
            searchAConceptSubtitle: t`search-a-concept-subtitle`, // "Vous pouvez taper des mots-clés qui s'y rapportent.",
          }}
        />
      </StyledTabPane>
    ),
    pageRoute: '/feedback',
  },
  {
    menuItem: t`feedback-form-page`,
    render: () => (
      <StyledTabPane>
        <CustomizePageWording
          campaignId={campaignId}
          page="feedback"
          inputLabels={{
            feedbackOn: t`feedback-on`, // 'Avis sur',
            satisfactionLevelQuestion: t`satisfaction-level-question`, // 'Êtes-vous satisfait(e) ?',
            openTextQuestion: t`open-text-question`, // 'Dites-nous en plus (commentaire, suggestion)',
            openTextPlaceholder: t`open-text-placeholder`, // "Je m'exprime ...",
            emptyHelper: t`empty-helper`, // 'Veuillez donner votre avis avant de contribuer',
            privacyPolicy: t`privacy-policy-title`, // 'politique de confidentialité',
            privacyNoticePrefix: t`privacy-noticeprefix`, // "En contribuant, j'accepte la",
            ctaText: t`publish-feedback-cta-text`, // 'Feedback send button',
          }}
        />
      </StyledTabPane>
    ),
    pageRoute: `/feedback/${firstGeneralFeedbackConceptId}`,
  },
  {
    menuItem: t`published-page`,
    render: () => (
      <StyledTabPane>
        <CustomizePageWording
          campaignId={campaignId}
          page="published"
          inputLabels={{
            title: t`title`, // 'Published Title',
            subtitle: t`subtitle`, // 'Published Subtitle',
            secondSubtitle: t`second-subtitle`, // 'Published Second Subtitle',
            allConceptsClearedSubtitle: t`all-concepts-cleared-subtitle`, // 'Published All Concepts Cleared Subtitle',
            searchCtaText: t`search-cta-text`, // 'Published Next Button',
          }}
        />
      </StyledTabPane>
    ),
    pageRoute: '/published',
  },
  {
    menuItem: t`identification-form`,
    render: () => (
      <StyledTabPane>
        <CustomizeIdentificationForm
          campaignId={campaignId}
          page="identification_wording"
          inputLabels={{
            header: t`questionnaire-title`, // 'Précisions complémentaires',
          }}
        />
      </StyledTabPane>
    ),
    pageRoute: `/feedback/${firstGeneralFeedbackConceptId}`,
  },
  {
    menuItem: t`faq-page`,
    render: () => (
      <StyledTabPane>
        <CustomizeFaqItems campaignId={campaignId} />
      </StyledTabPane>
    ),
    pageRoute: '',
  },
];

function CustomizeCampaignPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('');
  const previewActivated = useSelector(previewActivatedSelector);
  const restorationActivated = useSelector(restorationActivatedSelector);
  // get concepts from states
  const concepts = useSelector(conceptsSelector(null));

  const firstGeneralFeedbackConceptId = Object.keys(concepts)[0];
  const campaignConfigurationIsLoading = useMemoizedFactorySelector(
    campaignConfigurationIsLoadingSelectorFactory,
    campaignId
  );
  const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector);
  useEffect(() => {
    const saveBeforeUnload = () => {
      dispatch(saveCampaignConfigurationAsDraft(campaignId));
    };
    // We add an event listener on beforeunload to send the diff
    // to the backend and not losing the customization
    window.addEventListener('beforeunload', saveBeforeUnload);
    return () => window.removeEventListener('beforeunload', saveBeforeUnload);
  }, []);
  const panes = useMemo(
    () =>
      getCustomizationPanes(
        campaignId,
        firstGeneralFeedbackConceptId,
        restorationActivated
      ),
    [campaignId, firstGeneralFeedbackConceptId, restorationActivated]
  );
  if (campaignConfigurationIsLoading) return null;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ margin: `${svars.spaceNormal} ${svars.spaceLarge}` }}>
        {restorationActivated && (
          <Segment
            style={{
              maxWidth: '700px',
              padding: `${svars.spaceMediumLarge} ${svars.spaceNormalLarge} ${svars.spaceMedium} ${svars.spaceMedium}`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              margin: `${svars.spaceMedium} auto`,
            }}
          >
            <span style={{ flexGrow: 1, marginBottom: svars.spaceMediumLarge }}>
              <Trans id="campaign-customization.saved-draft-restore-message" />
            </span>
            <div
              style={{
                alignSelf: 'flex-end',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <ButtonTransparentAccent
                onClick={() => {
                  dispatch(deactivateRestoreDiff());
                  dispatch(deletePreviewConfig(campaignId));
                }}
              >
                <Trans id="forget-changes" />
              </ButtonTransparentAccent>
              <ButtonSecondaryAccent
                onClick={() => dispatch(restoreCachedDiff(campaignId))}
              >
                <Trans id="restore-changes" />
              </ButtonSecondaryAccent>
            </div>
          </Segment>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            float: 'left',
            width: '70%',
            padding: `${svars.spaceNormal} ${svars.spaceLarge}`,
          }}
        >
          <Tab
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            panes={panes}
            onTabChange={(event, data) => {
              setActiveTab(data.panes[data.activeIndex].pageRoute);
            }}
            renderActiveOnly
            menu={{ color: 'blue', secondary: true, pointing: true }}
          />
        </div>
        <div
          style={{
            float: 'right',
            width: '30%',
            height: '100%',
            margin: `${svars.spaceNormal} ${svars.spaceLarge}`,
          }}
        >
          {previewActivated ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div
                style={{
                  margin: `${svars.spaceXSmall} ${svars.spaceMedium}`,
                  display: 'inline-flex',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                <ButtonSecondaryAccent
                  style={{ margin: svars.spaceNormal }}
                  onClick={async () => {
                    await dispatch(
                      saveCampaignConfigurationAsDraft(campaignId)
                    );
                    reloadIframe();
                  }}
                  disabled={!previewActivated}
                >
                  <Trans id="reload-preview" />
                </ButtonSecondaryAccent>
                <ButtonSecondaryDanger
                  style={{ margin: svars.spaceNormal }}
                  onClick={() => dispatch(closePreview())}
                >
                  <Trans id="close-preview" />
                </ButtonSecondaryDanger>
              </div>
              {hasUnsavedChanges && (
                <span style={{ color: svars.colorWarning }}>
                  <Trans id="reload-preview-to-include-recent-changes" />
                </span>
              )}
              <iframe
                src={`${config.PREVIEW_URL}${activeTab}?campaign=${campaignId}&preview=true`}
                title="preview"
                style={{
                  width: '100%',
                  margin: svars.spaceNormalLarge,
                  border: `1px solid ${svars.colorBlack}`,
                  borderRadius: svars.borderRadius,
                  overflow: 'hidden',
                  flexGrow: 1,
                }}
                id="preview_iframe"
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  margin: `${svars.spaceXSmall} ${svars.spaceMedium}`,
                  display: 'inline-flex',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                <ButtonSecondaryAccent
                  style={{
                    margin: svars.spaceNormal,
                  }}
                  onClick={async () => {
                    await dispatch(
                      saveCampaignConfigurationAsDraft(campaignId)
                    );
                    dispatch(openPreview());
                  }}
                >
                  <Trans id="start-preview" />
                </ButtonSecondaryAccent>
                <ButtonSecondaryAccent
                  style={{
                    margin: svars.spaceNormal,
                  }}
                  onClick={async () => {
                    await dispatch(
                      saveCampaignConfigurationAsDraft(campaignId)
                    );
                    window.open(
                      `${config.PREVIEW_URL}${activeTab}?campaign=${campaignId}&preview=true`,
                      '_blank'
                    );
                  }}
                >
                  <Trans id="open-preview-in-new-tab" />
                </ButtonSecondaryAccent>
              </div>
              <div
                style={{
                  width: '100%',
                  margin: svars.spaceNormalLarge,
                  border: `1px solid ${svars.colorBlack}`,
                  borderRadius: svars.borderRadius,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  verticalAlign: 'middle',
                  background: `content-box radial-gradient(${svars.colorLightestGrey}, ${svars.colorLightGrey})`,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <SaveControl pageRoute={activeTab} campaignId={campaignId} />
      <TimeoutLogic
        logOutMethod={() => {
          dispatch(deletePreviewConfig(campaignId));
          navigate('/campaign');
        }}
        automaticLogOutMethod={() => {
          dispatch(saveCampaignConfigurationAsDraft(campaignId));
          navigate('/campaign');
        }}
        firstDelay={6000000}
        secondDelay={60000}
      />
    </div>
  );
}

CustomizeCampaignPage.propTypes = {};

export default CustomizeCampaignPage;
