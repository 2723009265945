import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import {
  addUserFormElement,
  removeFormElement,
  updateFormElement,
} from 'actions/campaign';
import {
  formSelectorFactory,
  onGenerateTagSetsOptionsSelector,
} from 'selectors/campaign';

import FormItemsManagementList from 'components/ui/ManagementList/FormItemsManagementList';
import emptyPageUrl from 'components/ui/svg/undraw_annotation_re_h774.svg';

import { useMemoizedFactorySelector } from 'utils/hooks';

const CAMPAIGN_FIELD = 'annotation_form';

function AnnotationFormTabPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  const annotationForm = useMemoizedFactorySelector(
    formSelectorFactory,
    campaignId,
    CAMPAIGN_FIELD
  );
  const tagSetsOptions = useSelector(onGenerateTagSetsOptionsSelector);
  const onAddAnnotationFormElement = useCallback(
    (element) =>
      dispatch(addUserFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onRemoveAnnotationFormElement = useCallback(
    (element) =>
      dispatch(removeFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onUpdateFormElement = useCallback(
    (element) =>
      dispatch(updateFormElement(campaignId, CAMPAIGN_FIELD, element)),
    [campaignId]
  );
  return (
    <FormItemsManagementList
      items={annotationForm}
      addItemLabel={t`add-annotation-form-element`}
      editItemLabel={t`edit-annotation-form-element`}
      restoreItemLabel={t`restore-annotation-form-element`}
      removeItemLabel={t`remove-annotation-form-element`}
      resetAllLabel={t`reset-annotation-form-element`}
      emptyListHeader={t`annotation-form-element.list-empty-header`}
      emptyListMessage={t`annotation-form-element.list-empty-message`}
      onAddElement={onAddAnnotationFormElement}
      onUpdateElement={onUpdateFormElement}
      onRemoveElement={onRemoveAnnotationFormElement}
      tagSetsOptions={tagSetsOptions}
      emptyDataIllustration={emptyPageUrl}
      mutableIsAllowed
    />
  );
}

AnnotationFormTabPage.propTypes = {};

export default AnnotationFormTabPage;
