import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { actionTypes as campaignActionTypes } from 'actions/campaign';
import { campaignChannelsItemsSelector } from 'reducers/entityLabelFormatter';
import { loadingStateSelector } from 'reducers/ui';
import {
  campaignOntologySelectorFactory,
  campaignSatisfactionTagSetIdSelectorFactory,
} from 'selectors/campaign';
import { tagSetItemsSelector } from 'selectors/facet';
import {
  displayFiltersSelector,
  filtersSelector,
  oneFilterSelector,
  resetFilters,
  satisfactionTagFilterValuesSelector,
  shouldUpdateSearchSelector,
  tagFilterValuesSelector,
  toggleFiltersPaneAndMaybeFetchFeedback,
  updateFilters,
  updateOneTagsFilter,
  updateTagsFilters,
} from 'store/monitor/monitorSearchSlice';

import { FORMS_ICON_MAP } from 'components/customer/campaign/campaignSubRoutes';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button';
import SidePane from 'components/ui/panels/SidePane';
import FiltersAccordion from 'components/ui/search/FiltersAccordion';
import staticFieldFilterFactory from 'components/ui/search/staticFieldFilterFactory';

import * as svars from 'assets/style/variables';

import CheckboxListFilter from '../../analysis/search/CheckboxListFilter';
import { OntologyFilterFactory } from '../../analysis/search/OntologyFilter';
import TextSearchFilter from '../../analysis/search/TextSearchFilter';
import {
  useAnnotationForm,
  useCampaignFormFields,
  useIdentificationForm,
  useUrlForm,
} from '../useCampaignFields';

export const formFieldFilterFactory = (
  valuesSelector,
  onUpdateTagsFilters,
  onResetFilters,
  loading
) => {
  function FormFieldFilter({
    id,
    header,
    icon,
    iconHelp,
    tagSetId,
    feedbackFormValuesFieldName,
    isActive,
    onToggle,
    setFilterModalIsActive,
  }) {
    const values = useSelector(
      valuesSelector({
        id,
        tagSetId,
        feedbackFormValuesFieldName,
      }),
      shallowEqual
    );
    const tagSet = useSelector(
      tagSetItemsSelector(tagSetId, true),
      shallowEqual
    );
    const onFilterChange = onUpdateTagsFilters(feedbackFormValuesFieldName, id);
    // Only other type of field is text, and does not have a related tag set
    const isTextField = !tagSetId;

    return (
      <FiltersAccordion.Filter
        title={header}
        icon={icon}
        iconHelp={iconHelp}
        nFilterValues={values ? values.length : 0}
        isActive={isActive}
        onToggle={onToggle}
      >
        {isTextField ? (
          <TextSearchFilter
            valueKey="id"
            onFilterChange={onFilterChange}
            textSearchValues={values}
            onSetFilterActive={setFilterModalIsActive}
          />
        ) : (
          <CheckboxListFilter
            valueKey="id"
            items={tagSet?.items || []}
            values={values}
            onFilterChange={onFilterChange}
            onFilterReset={onResetFilters(
              id,
              true,
              feedbackFormValuesFieldName
            )}
            loading={loading}
          />
        )}
      </FiltersAccordion.Filter>
    );
  }
  FormFieldFilter.propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    tagSetId: PropTypes.string,
    feedbackFormValuesFieldName: PropTypes.string,
    // These props are injected by FiltersAccordion
    // They cannot be initially required but they are always provided
    isActive: PropTypes.bool,
    onToggle: PropTypes.func,
    setFilterModalIsActive: PropTypes.func,
    icon: PropTypes.string,
    iconHelp: PropTypes.string,
  };
  FormFieldFilter.defaultProps = {
    feedbackFormValuesFieldName: null,
    isActive: false,
    onToggle: null,
    setFilterModalIsActive: null,
    tagSetId: null,
    // Icon to be displayed close to the filter title
    icon: null,
    // Icon help text displayed on hover
    iconHelp: null,
  };
  return FormFieldFilter;
};

function ChannelFilter({
  channels: values,
  onFilterChange,
  onFilterReset,
  loading,
}) {
  const { campaignId } = useParams();
  const channels = useSelector(campaignChannelsItemsSelector(campaignId));
  return (
    <CheckboxListFilter
      valueKey="channels"
      items={channels}
      values={values}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
      loading={loading}
    />
  );
}

ChannelFilter.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
ChannelFilter.defaultProps = { loading: false };

function MonitoringFilters({ onValidateFilters, resetAllFilters }) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  // toggle to true when a filter has an open modal, to lock the sidepane and
  // avoid that closing the modal closes the sidepane
  const [filterModalIsActive, setFilterModalIsActive] = useState(false);

  const shouldUpdateSearch = useSelector(shouldUpdateSearchSelector);
  const visible = useSelector(displayFiltersSelector);
  const filters = useSelector(filtersSelector);
  const campaignIsLoading = useSelector(
    loadingStateSelector([
      campaignActionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
    ])
  );
  const satisfactionTagSetId = useSelector(
    campaignSatisfactionTagSetIdSelectorFactory(campaignId)
  );
  const OntologyFilter = useMemo(
    () =>
      OntologyFilterFactory(
        () => (state) =>
          campaignOntologySelectorFactory(campaignId)(state)?.concepts || [],
        () => ({}),
        ({ value }, selectedItems) =>
          !!selectedItems.find((dbId) => dbId === value)
      ),
    [campaignId]
  );

  const formFields = useCampaignFormFields({
    useIdentificationForm,
    useUrlForm,
    useAnnotationForm,
  });
  const onToggle = useCallback(
    () => dispatch(toggleFiltersPaneAndMaybeFetchFeedback({ campaignId })),
    [campaignId]
  );
  const onUpdateFilters = useCallback(
    (payload) => dispatch(updateFilters(payload)),
    []
  );
  const onUpdateTagsFilters = useCallback(
    (feedbackFormValuesFieldName, tagSetId) =>
      ({ id: itemIds }) =>
        dispatch(
          updateTagsFilters({ feedbackFormValuesFieldName, tagSetId, itemIds })
        ),
    [updateTagsFilters]
  );
  const onUpdateOneTagFilters = useCallback(
    (feedbackFormValuesFieldName, tagSetId) =>
      ({ id: itemIds }) =>
        dispatch(
          updateOneTagsFilter({
            feedbackFormValuesFieldName,
            tagSetId,
            itemIds,
          })
        ),
    [updateTagsFilters]
  );

  const onResetFilters = useCallback(
    (filterKey = null, isTag = false, feedbackFormValuesFieldName = null) =>
      () =>
      () =>
        dispatch(
          resetFilters({ filterKey, feedbackFormValuesFieldName, isTag })
        ),
    []
  );
  const onResetOneTagFilters = useCallback(
    (filterKey, isTag = false, feedbackFormValuesFieldName = null) =>
      () =>
      () =>
        dispatch(
          resetFilters({ filterKey: null, feedbackFormValuesFieldName, isTag })
        ),
    []
  );
  const FormFieldFilter = useMemo(
    () =>
      formFieldFilterFactory(
        tagFilterValuesSelector,
        onUpdateTagsFilters,
        onResetFilters,
        campaignIsLoading
      ),
    [
      tagFilterValuesSelector,
      onUpdateTagsFilters,
      onResetFilters,
      campaignIsLoading,
    ]
  );
  const SatisfactionTagFilter = useMemo(
    () =>
      formFieldFilterFactory(
        satisfactionTagFilterValuesSelector,
        onUpdateOneTagFilters,
        onResetOneTagFilters,
        campaignIsLoading
      ),
    [
      satisfactionTagFilterValuesSelector,
      onUpdateOneTagFilters,
      onResetOneTagFilters,
      campaignIsLoading,
    ]
  );
  const StaticFilter = useMemo(
    () =>
      staticFieldFilterFactory(
        oneFilterSelector,
        onUpdateFilters,
        onResetFilters('channels', false),
        campaignIsLoading
      ),
    [onUpdateFilters, onResetFilters, campaignIsLoading]
  );
  return (
    <SidePane
      visible={visible}
      onToggle={onToggle}
      animation="push"
      direction="right"
      width="very wide"
      dimmed
      locked={filterModalIsActive}
    >
      <SidePane.Header
        title={t`Filters`}
        onToggle={onToggle}
        gaCategory="Campaign - filter panel"
      />
      <SidePane.Body>
        <FiltersAccordion defaultActiveFilterIndex={0}>
          <StaticFilter
            title={t`campaign-channels`}
            keys={['channels']}
            key="channels"
            FilterComponent={ChannelFilter}
          />
          <FiltersAccordion.Filter
            title={t`keywords`}
            nFilterValues={filters.textSearchValues?.length ? 1 : 0}
          >
            <TextSearchFilter
              onFilterChange={onUpdateFilters}
              textSearchValues={filters.textSearchValues}
              onSetFilterActive={setFilterModalIsActive}
            />
          </FiltersAccordion.Filter>
          <FiltersAccordion.Filter
            title={t`concepts`}
            nFilterValues={filters.concepts ? filters.concepts.length : 0}
          >
            <OntologyFilter
              hideAllConceptsToggle
              filtersKey="concepts"
              ontologyConcepts={filters.concepts}
              matchAllConcepts={filters.matchAllConcepts}
              onFilterChange={onUpdateFilters}
              onFilterReset={onResetFilters('concepts', false)}
            />
          </FiltersAccordion.Filter>

          {SatisfactionTagFilter({
            id: '123',
            key: 'test',
            header: t`satisfaction-level`,
            tagSetId: satisfactionTagSetId,
            feedbackFormValuesFieldName: 'satisfaction_tag',
          })}
          {formFields
            ? Object.entries(formFields).map(([formLabel, fields], i) =>
                fields.map((props) => (
                  <FormFieldFilter
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    setFilterModalIsActive={setFilterModalIsActive}
                    {...FORMS_ICON_MAP[formLabel]}
                    {...props}
                  />
                ))
              )
            : null}
        </FiltersAccordion>
      </SidePane.Body>
      <SidePane.Actions visible>
        <AnalyticsAwareButton
          gaCategory="Campaign - search"
          gaAction="Reset filters"
          inputComponent={ButtonTransparentDanger}
          labelPosition="right"
          content={t`reset-filters`}
          onClick={resetAllFilters}
          icon="refresh"
          style={{ marginRight: svars.spaceMediumLarge }}
          nowrap="true"
        />
        <AnalyticsAwareButton
          gaCategory="Campaign - search"
          gaAction="Apply filters"
          inputComponent={ButtonAccent}
          labelPosition="right"
          content={t`apply-filters`}
          onClick={onValidateFilters}
          icon="filter"
          disabled={!shouldUpdateSearch}
          nowrap="true"
        />
      </SidePane.Actions>
    </SidePane>
  );
}
MonitoringFilters.propTypes = {
  onValidateFilters: PropTypes.func.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
};

export default MonitoringFilters;
