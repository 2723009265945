import React from 'react';

import { t } from '@lingui/macro';

import DistributionVisualization from 'components/customer/visualization/DistributionVisualization';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

function Distribution({
  chartId,
  height,
  width,
  data,
  testid,
  meta: { cellKey, indicatorKey, minColor, maxColor },
  formatters: { colorFormatter, labelFormatter },
}) {
  const layout =
    (height && width && height / width < 0.7 && 'horizontal') || 'vertical';
  return (
    <DistributionVisualization
      layout={layout}
      height={height}
      data={data}
      loading={false}
      chartId={chartId}
      data-testid={testid}
      cellKey={cellKey}
      indicatorKey={indicatorKey}
      legendFormatter={labelFormatter}
      tooltipHeaderField={{
        key: cellKey,
        isEntity: false,
        formatter: labelFormatter,
      }}
      othersCellLabel={(nSource) => t`+ ${nSource} others`}
      minColor={minColor}
      maxColor={maxColor}
      colorFormatter={colorFormatter}
    />
  );
}

Distribution.propTypes = commonPropTypes.dashboardChart;
Distribution.defaultProps = {
  data: [],
  meta: {},
  formatters: {},
};
Distribution.minHeight = svars.chartHeight;
Distribution.minWidth = svars.chartMinWidth;

export default Distribution;
