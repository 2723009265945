import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  focusChannelSelector,
  focusOnNextChannel,
  setFocusChannelId,
} from 'store/monitor/channelsSlice';

import canvasToImage from 'canvas-to-image';
import { QRCodeCanvas } from 'qrcode.react';

import { ButtonTransparentAccent } from 'components/ui/button';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import SidePane from 'components/ui/panels/SidePane';
import logoImageSrc from 'components/ui/svg/logo.svg';
import { CopyableTextCell } from 'components/ui/table/cells/ReactTableCell';
import { ActivityIndicatorCell } from 'components/ui/table/cells/dotCells';

import config from 'config';
import commonPropTypes from 'utils/commonPropTypes';
import { dateTimeFormatter } from 'utils/formatter';
import { capitalize } from 'utils/helpers';
import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

const getQrCodeElementId = (channelName) =>
  `qrcode-${channelName?.replace(/\s/g, '_')}`;

const onDownloadQrCode = (qrCodeElementId, filename) => () => {
  // Generate download with use canvas and stream
  canvasToImage(document.getElementById(qrCodeElementId), {
    name: `Qrcode-${filename}`,
    type: 'png',
    quality: 1,
  });
};

const ChannelFieldContainer = styled.div`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: ${svars.spaceMedium};
  background: ${svars.colorWhite};
`;

const FieldHeader = styled.div`
  color: ${svars.fontColorLighter};
  padding-bottom: ${svars.spaceSmall};
  padding-right: ${svars.spaceNormalLarge};
`;

const FieldValue = styled.div`
  font-size: ${svars.fontSizeLarge};
`;

const QR_CODE_DISPLAY_WIDTH_PX = 400;

function QRCodeCell({ value, channel }) {
  const [whiteAndBorder, setWhiteAndBorder] = useState(true);
  const elementId = getQrCodeElementId(channel?.name);
  const toggleWhiteAndBorder = useCallback(() => {
    setWhiteAndBorder(!whiteAndBorder);
  }, [whiteAndBorder]);
  const includeMargin = whiteAndBorder
    ? { bgColor: 'white', includeMargin: true }
    : { bgColor: 'transparent' };

  return (
    <div
      style={{
        border: `1px solid ${svars.colorLightGrey}`,
        boxShadow: svars.baseBoxShadow,
        borderRadius: svars.borderRadius,
        textAlign: 'right',
        margin: 'auto',
        padding: svars.spaceNormal,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Checkbox
          checked={whiteAndBorder}
          label={t`with-white-background-and-margin`}
          onClick={toggleWhiteAndBorder}
          data-testid="bo-channel-panel-margin-bg-checkbox"
        />
        <AnalyticsAwareHoverableIconButtonWithTooltip
          help={t`download-qr-code`}
          name="download"
          onClick={onDownloadQrCode(elementId, channel?.name)}
          labelPosition="left"
          data-testid="bo-channel-panel-download-qrcode-button"
        />
      </div>

      <div role="presentation" style={{ textAlign: 'center' }}>
        <QRCodeCanvas
          id={elementId}
          value={value}
          {...includeMargin}
          fgColor={svars.colorPrimary}
          size={3 * QR_CODE_DISPLAY_WIDTH_PX}
          style={{
            height: `${QR_CODE_DISPLAY_WIDTH_PX}px`,
            width: `${QR_CODE_DISPLAY_WIDTH_PX}px`,
          }}
          imageSettings={{
            src: logoImageSrc,
            excavate: true,
            height: 0,
            width: 0,
          }}
        />
      </div>
    </div>
  );
}

QRCodeCell.propTypes = {
  value: PropTypes.string,
  channel: commonPropTypes.channel,
};
QRCodeCell.defaultProps = { value: null, channel: null };
export function QRCodeModal({ open, value, onClose, channelName }) {
  const qrCodeElementId = getQrCodeElementId(channelName);
  return (
    <Modal dimmer onClose={onClose} open={open} size="small">
      <Modal.Content>
        <QRCodeCell
          elementId={qrCodeElementId}
          value={value}
          defaultDisplayed
        />
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentAccent
          inverted
          onClick={onClose}
          content={t`close`}
        />
      </Modal.Actions>
    </Modal>
  );
}
QRCodeModal.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  channelName: PropTypes.string,
};
QRCodeModal.defaultProps = { open: false, value: null, channelName: null };

const appRouteFormatterAndAccessor = {
  accessor: (item) => item.app_route,
  formatter: (route) => `${config.PREVIEW_URL}${route}`,
};
function ChannelFields({ channel }) {
  const fields = [
    {
      header: t`name`,
      accessor: (item) => item.name,
      inline: true,
      testid: 'channel-header',
    },
    {
      header: t`creation-date`,
      accessor: (item) => item.create_date,
      formatter: dateTimeFormatter,
      inline: true,
      testid: 'channel-creation-date',
    },
    {
      header: t`responses`,
      accessor: (item) => item.n_feedback,
      Cell: ActivityIndicatorCell,
      inline: true,
      testid: 'channel-response-indicator',
    },
    {
      header: t`url`,
      Cell: CopyableTextCell,
      testid: 'channel-url',
      ...appRouteFormatterAndAccessor,
    },
    {
      header: t`qr-code`,
      Cell: QRCodeCell,
      testid: 'channel-qrcode',
      ...appRouteFormatterAndAccessor,
    },
  ];
  return fields.map(({ header, accessor, formatter, Cell, inline, testid }) => {
    const value =
      (formatter && formatter(accessor(channel))) || accessor(channel) || '-';
    return (
      <ChannelFieldContainer key={`${header}`} inline={inline ? 1 : 0}>
        <FieldHeader>{capitalize(header)} : </FieldHeader>
        <FieldValue data-testid={testid}>
          {Cell ? <Cell value={value} channel={channel} /> : value}
        </FieldValue>
      </ChannelFieldContainer>
    );
  });
}

ChannelFields.propTypes = {};
ChannelFields.defaultProps = { Cell: null, formatter: (item) => item };

function ChannelPane() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const channel = useMemoizedFactorySelector(focusChannelSelector, campaignId);
  const onToggle = useCallback(() => {
    if (channel) dispatch(setFocusChannelId(null));
  }, [channel, campaignId]);
  const onFocusPreviousChannel = useCallback(() => {
    if (channel)
      dispatch(focusOnNextChannel({ campaignId, selectNext: false }));
  }, [channel, campaignId]);
  const onFocusNextChannel = useCallback(() => {
    if (channel) dispatch(focusOnNextChannel({ campaignId, selectNext: true }));
  }, [channel, campaignId]);

  return (
    <SidePane
      dimmed
      onToggle={onToggle}
      animation="push"
      direction="right"
      visible={channel !== null}
      width="very wide"
      testid="bo-campaign-channel-panel-toggle-off-button"
    >
      <SidePane.Header
        title={t`channel-details`}
        onToggle={onToggle}
        onSelectPrevious={onFocusPreviousChannel}
        onSelectNext={onFocusNextChannel}
        onSelectPreviousHelp={t`previous-channel`}
        onSelectNextHelp={t`next-channel`}
        gaCategory="Campaign - channel panel"
        testid="bo-campaign-channel-panel-toggle-prev-after-button"
      />
      <SidePane.Body>
        {channel ? (
          <ChannelFields
            channel={channel}
            testid={`channel-pannel-${channel.name}-value`}
          />
        ) : null}
      </SidePane.Body>
    </SidePane>
  );
}
ChannelPane.propTypes = {};
export default ChannelPane;
