import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form, Grid, Icon } from 'semantic-ui-react';

import Header from 'components/ui/Header';
import { NormalSpacePaddedSegment } from 'components/ui/Segment';

import * as svars from 'assets/style/variables';

function FacetCreateField({
  name,
  input,
  helpMessage,
  legend,
  inputWidth,
  errorMessage,
}) {
  return (
    <Grid.Row
      as={NormalSpacePaddedSegment}
      style={{ padding: svars.spaceMedium }}
    >
      {name && (
        <Grid.Column width={16}>
          <Header style={{ marginBottom: svars.spaceMedium }}>{name}</Header>
        </Grid.Column>
      )}
      <Grid.Column width={inputWidth} textAlign="left">
        <Form.Field inline>{input}</Form.Field>
      </Grid.Column>
      {helpMessage && (
        <Grid.Column width={16 - inputWidth} floated="right">
          <i>
            {(typeof helpMessage === 'object' &&
              helpMessage.length &&
              helpMessage.map((item) =>
                !item.type ? (
                  <p key={`help-message-${item.id || item}`}>
                    <Trans id={item} />
                  </p>
                ) : (
                  <Trans id={item} />
                )
              )) ||
              (typeof helpMessage === 'string' && <Trans id={helpMessage} />) ||
              helpMessage}
          </i>
        </Grid.Column>
      )}

      {legend && (
        <Grid.Column width={16} style={{ paddingTop: svars.spaceMedium }}>
          {legend}
        </Grid.Column>
      )}

      {errorMessage ? (
        <Grid.Column
          width={16}
          style={{
            paddingTop: svars.spaceMedium,
            color: svars.colorDanger,
            fontStyle: 'italic',
          }}
        >
          <Icon name="attention" /> {errorMessage}
        </Grid.Column>
      ) : null}
    </Grid.Row>
  );
}

FacetCreateField.propTypes = {
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  input: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  helpMessage: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string,
  ]),
  legend: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  inputWidth: PropTypes.number,
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

FacetCreateField.defaultProps = {
  name: '',
  helpMessage: '',
  legend: null,
  inputWidth: 7,
  errorMessage: null,
};

export default FacetCreateField;
