import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { throttle } from 'lodash';
import { Divider, Grid } from 'semantic-ui-react';

import {
  actionTypes as campaignActionTypes,
  createCampaign,
} from 'actions/campaign';
import { maybeFetchProductHierarchies } from 'actions/entities';
import { api } from 'actions/utils';
import { loadingStateSelector } from 'reducers/ui';
import { productHierarchiesItemsSelector } from 'selectors/entities';

import * as Sentry from '@sentry/react';

import FacetCreateField from 'components/customer/home/view-facet-create/FacetCreateField';
import { LargeHeader } from 'components/ui/Header';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import { SelectBox } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import { ButtonLineLayout, PageLayout } from 'components/ui/layout/Page';
import SvgContentUrl from 'components/ui/svg/undraw_content_vbqo.svg';

import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const getCampaignLanguages = () => [
  { key: 'en', value: 'en', text: capitalize(i18n._(t`english`)), flag: 'us' },
  { key: 'fr', value: 'fr', text: capitalize(i18n._(t`french`)), flag: 'fr' },
  {
    key: 'zh',
    value: 'zh',
    text: capitalize(i18n._(t`simplified-chinese`)),
    flag: 'zh',
  },
];

const CAMPAIGN_CREATE_HELP_TEXT_IDS = [
  t`campaign-create-help.campaign-allow-feedback-collection`,
  t`campaign-create-help.campaign-can-be-shared`,
];
/**
 * Check campaign generated slug name is not used.
 *
 * @param {*} slugName the slug name to test.
 * @param {*} callback a callback to update state.
 */
const checkIsAvailableCampaignName = async (name, callback) => {
  let response;
  try {
    response = await api.get(`/campaign-check?name=${name}`);
  } catch (error) {
    return callback(false);
  }
  return callback(response.data.unique);
};

const campaignCreateLoadingSelector = loadingStateSelector([
  campaignActionTypes.CREATE_CAMPAIGN_REQUEST,
]);

function CreateCampaignPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignName, setCampaignName] = useState('');
  const [isCampaignNameValid, setIsCampaignValid] = useState(null);
  const [hierarchyId, setHierarchyId] = useState(null);

  const campaignLanguages = useMemo(() => getCampaignLanguages(), []);

  const [language, setLanguage] = useState(campaignLanguages[0].value);

  useEffect(() => dispatch(maybeFetchProductHierarchies()), []);

  const productHierarchiesItems = useSelector(productHierarchiesItemsSelector);
  const isCampaignCreateLoading = useSelector(campaignCreateLoadingSelector);

  const validateSlugName = useCallback(
    throttle(
      (value) => {
        if (value) {
          checkIsAvailableCampaignName(value, setIsCampaignValid);
        }
      },
      350,
      { leading: false, trailing: true }
    ),
    [setIsCampaignValid]
  );
  const onNameChange = useCallback(({ target: { value } }) => {
    setCampaignName(value);
    if (value !== '') {
      validateSlugName(value);
    }
  }, []);
  const onHierarchyChange = useCallback((e, data) => {
    if (data.value) {
      setHierarchyId(data.value);
    } else {
      setHierarchyId(null);
    }
  }, []);
  const onLanguageChange = useCallback((e, data) => {
    if (data.value) {
      setLanguage(data.value);
    } else {
      setLanguage(null);
    }
  }, []);

  const onValidateCreate = useCallback(async () => {
    const campaignId = await dispatch(
      createCampaign(campaignName, hierarchyId, language)
    );
    if (typeof campaignId === 'string') {
      navigate(`../${campaignId}`);
    } else {
      // Error creating campaign
      Sentry.captureException(`Could not create campaign : ${campaignId}`);
    }
  }, [campaignName, hierarchyId, language]);
  return (
    <PageLayout simple>
      <Grid
        style={{
          flexGrow: 1,
          padding: svars.spaceMediumLarge,
          paddingBottom: svars.spaceMassive,
          overflowY: 'auto',
        }}
      >
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={9}>
            <LargeHeader>
              <Trans id="create-a-new-campaign" />
            </LargeHeader>
            <div style={{ maxWidth: svars.textMaxWidth }}>
              {CAMPAIGN_CREATE_HELP_TEXT_IDS.map((item) => (
                <p key={`text-it-${item.id || item}`}>
                  <Trans id={item} />
                </p>
              ))}
            </div>
          </Grid.Column>
          <Grid.Column
            width={7}
            floated="right"
            textAlign="right"
            verticalAlign="bottom"
          >
            <img
              style={{
                maxHeight: '15rem',
                paddingRight: svars.spaceLarge,
              }}
              src={SvgContentUrl}
              alt="star"
            />
          </Grid.Column>
        </Grid.Row>

        <FacetCreateField
          input={
            <LimitedTextInput
              style={{
                width: '100%',
                fontWeight: svars.fontWeightBold,
                fontSize: svars.fontSizeXLarge,
              }}
              placeholder={t`campaign-create.title-placeholder`}
              value={campaignName}
              onChange={onNameChange}
              maxCharacters={80}
              data-testid="bo-create-campaign-name-input"
            />
          }
          errorMessage={
            campaignName && isCampaignNameValid === false
              ? t`campaign-name-already-used-choose-another`
              : null
          }
          inputWidth={15}
        />
        <FacetCreateField
          name={t`product(s)-service(s)`}
          input={
            <SelectBox
              placeholder={t`select-a-product(s)-service(s)`}
              loading={isCampaignCreateLoading}
              options={productHierarchiesItems || []}
              onChange={onHierarchyChange}
              value={hierarchyId}
              testid="bo-campaign-creation-product-list-input"
            />
          }
          helpMessage={[t`campaign-create.product-service-help`]}
          data-testid="bo-campaign-creation-product-selection-field"
        />
        <FacetCreateField
          name={t`language`}
          input={
            <SelectBox
              placeholder={t`choose-a-language`}
              loading={isCampaignCreateLoading}
              options={campaignLanguages}
              onChange={onLanguageChange}
              value={language}
            />
          }
          helpMessage={t`campaign-create.language-help`}
        />
      </Grid>
      <Divider />
      <ButtonLineLayout padded>
        <AnalyticsAwareButton
          gaCategory="Campaign management"
          gaAction="Campaign creation"
          gaLabel="cancel"
          inputComponent={ButtonTransparentAccent}
          onClick={() => navigate('..')}
          data-testid="bo-campaign-creation-cancel-button"
        >
          <Trans id="cancel" />
        </AnalyticsAwareButton>
        <AnalyticsAwareButton
          gaCategory="Campaign management"
          gaAction="Campaign creation"
          gaLabel="create"
          inputComponent={ButtonAccent}
          onClick={onValidateCreate}
          disabled={
            isCampaignCreateLoading ||
            !(isCampaignNameValid === true && hierarchyId)
          }
          loading={isCampaignCreateLoading}
          style={{ marginLeft: svars.spaceMediumLarge }}
          data-testid="bo-campaign-creation-create-button"
        >
          <Trans id="create" />
        </AnalyticsAwareButton>
      </ButtonLineLayout>
    </PageLayout>
  );
}

CreateCampaignPage.propTypes = {};

export default CreateCampaignPage;
