import React from 'react';

import PropTypes from 'prop-types';

import FeedbackAppCustomizationInput from 'components/ui/inputs/FeedbackAppCustomizationInput';

function CustomizePageWording({ campaignId, page, inputLabels }) {
  return (
    <>
      {Object.entries(inputLabels).map(([inputKey, inputLabel]) => (
        <FeedbackAppCustomizationInput
          key={`key_${inputKey}_string`}
          {...{
            inputLabel,
            campaignId,
            page,
            inputKey,
          }}
        />
      ))}
    </>
  );
}

CustomizePageWording.propTypes = {
  campaignId: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  inputLabels: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CustomizePageWording;
