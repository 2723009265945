import { t } from '@lingui/macro';

const getCampaignSubRoutes = (isAdmin) => [
  {
    to: 'monitor',
    header: t`campaign-monitoring`,
    description: t`campaign-monitor.description`,
    ctaText: t`campaign-monitoring-cta`,
    icon: 'chart bar',
  },
  {
    to: 'channels',
    header: t`campaign-channels`,
    description: t`campaign-channels.description`,
    ctaText: t`campaign-channels-cta`,
    icon: 'share alternate',
  },
  ...(isAdmin
    ? [
        {
          to: 'customize',
          header: t`campaign-customization`,
          description: t`campaign-customization.description`,
          ctaText: t`campaign-customization-cta`,
          icon: 'cog',
        },

        {
          to: 'administrate',
          header: t`campaign-administration`,
          description: t`campaign-administration.description`,
          ctaText: t`campaign-administration-cta`,
          icon: 'american sign language interpreting',
        },
      ]
    : []),
];

export const campaignHomeRoute = {
  to: '',
  ctaText: t`back-home`,
  icon: 'arrow left',
  active: true,
  color: 'blue',
};

export const FORMS_ICON_MAP = {
  annotationFormFields: {
    // icon: 'pencil',
    icon: 'tags',
    iconHelp: t`field-belongs-to-annotation-form`,
  },
  identificationFormFields: {
    // icon: 'user circle outline',
    icon: 'id badge',
    iconHelp: t`field-belongs-to-identification-form`,
  },
  urlFormFields: {
    icon: 'share alternate',
    iconHelp: t`field-belongs-to-url-form`,
  },
};

export default getCampaignSubRoutes;
