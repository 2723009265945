import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Divider } from 'semantic-ui-react';

import { setConceptHighlight, setConceptRandomization } from 'actions/campaign';
import {
  campaignOntologySelectorFactory,
  highlightConceptsSelectorFactory,
  randomizeSelectorFactory,
} from 'selectors/campaign';

import Header from 'components/ui/Header';
import { StyledTabPane } from 'components/ui/Tab';
import { SelectBox } from 'components/ui/inputs/Dropdown';

import * as svars from 'assets/style/variables';

import CustomizePageWording from './CustomizePageWording';
import TogglableSection from './TogglableSection';

function GeneralCampaignCustomizeTab() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const randomize = useSelector(randomizeSelectorFactory(campaignId));
  const conceptHighlights = useSelector(
    highlightConceptsSelectorFactory(campaignId)
  );

  const { concepts: conceptOptions } = useSelector(
    campaignOntologySelectorFactory(campaignId)
  );

  const onToggleConceptRandomization = useCallback(
    () => dispatch(setConceptRandomization(campaignId, !randomize)),
    [campaignId, randomize]
  );
  const onSelectHighlightedConcept = useCallback(
    (e, { value }) => dispatch(setConceptHighlight(campaignId, value)),
    []
  );

  return (
    <StyledTabPane>
      <TogglableSection
        checked={randomize}
        onChange={onToggleConceptRandomization}
        title={<Trans id="concept-randomization" />}
        subtitle={<Trans id="campaign-customization-randomization-help" />}
        gaCategory="Campaign - customization"
        gaAction="Randomize concepts display"
        gaLabel={`${randomize}`}
      />
      <Divider />

      <Header
        style={{
          marginTop: 0,
          marginBottom: svars.spaceNormal,
        }}
      >
        <Trans id="concept-highlight" />
        <Header.Subheader style={{ marginTop: svars.spaceNormal }}>
          <Trans id="campaign-customization-concept-highlight-help" />
        </Header.Subheader>
      </Header>
      <SelectBox
        placeholder={t`select-one-or-more-concepts`}
        fluid
        small="true"
        search
        selection
        multiple
        options={conceptOptions}
        onChange={onSelectHighlightedConcept}
        value={conceptHighlights}
        style={{
          paddingBottom: svars.spaceMedium,
        }}
      />
      <CustomizePageWording
        campaignId={campaignId}
        page="concept_highlight"
        inputLabels={{
          title: t`title`,
          subTitle: t`subtitle`,
          noMore: t`no-more-highlight-to-answer`,
        }}
      />
    </StyledTabPane>
  );
}

GeneralCampaignCustomizeTab.propTypes = {};

export default GeneralCampaignCustomizeTab;
