import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form, Grid, Header, Message, Modal } from 'semantic-ui-react';

import {
  actionTypes as entitiesActionTypes,
  maybeFetchProductHierarchies,
} from 'actions/entities';
import {
  createProductHierarchyGroup,
  updateProductHierarchyGroup,
} from 'actions/entity_groups';
import { createLoadingSelector } from 'reducers/ui';

import { MediumPaddedSegment } from 'components/ui/Segment';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import { HierarchicalCheckboxList } from 'components/ui/inputs/checkbox-list';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

class ProductHierarchyGroupCreateModal extends Component {
  static initializeStateWithExistingGroup = ({ name, items }) => ({
    groupName: name,
    productHierarchyItems: items,
  });

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    if (props.initialGroup !== undefined) {
      this.state = {
        ...this.state,
        ...ProductHierarchyGroupCreateModal.initializeStateWithExistingGroup(
          props.initialGroup
        ),
      };
    }
  }

  getInitialState() {
    return {
      groupName: '',
      productHierarchyItems: [],
      displayErrorMessage: null,
      hideWarning: false,
    };
  }

  componentDidMount() {
    const { onMaybeFetchProductHierarchies } = this.props;
    onMaybeFetchProductHierarchies();
  }

  componentDidUpdate(prevProps) {
    const { initialGroup, open } = this.props;
    if (initialGroup && prevProps.initialGroup !== initialGroup) {
      this.setState({
        ...ProductHierarchyGroupCreateModal.initializeStateWithExistingGroup(
          initialGroup
        ),
      });
    } else if (prevProps.open === false && open) {
      this.setState(this.getInitialState());
    }
  }

  createOrUpdateGroup = async (
    id,
    name,
    productHierarchyItems,
    onSuccess = null,
    onError = null
  ) => {
    const { onUpdateProductHierarchyGroup, onCreateProductHierarchyGroup } =
      this.props;
    const baseParams = {
      name,
      productHierarchies: productHierarchyItems,
    };
    let actionResult;
    if (id) {
      actionResult = await onUpdateProductHierarchyGroup({
        id,
        ...baseParams,
      });
    } else {
      actionResult = await onCreateProductHierarchyGroup(baseParams);
    }
    if (onSuccess && /SUCCESS/i.exec(actionResult.type)) {
      onSuccess(actionResult.productHierarchyGroup);
    } else if (onError && /FAILURE/i.exec(actionResult.type)) {
      onError(actionResult);
    }
  };

  dismissWarning = () => this.setState({ hideWarning: true });

  onCreateOrUpdateGroup = () => {
    const { initialGroup, onClose } = this.props;
    const { groupName, productHierarchyItems } = this.state;
    this.createOrUpdateGroup(
      initialGroup ? initialGroup.id : null,
      groupName,
      productHierarchyItems,
      (group) => onClose(group.id),
      () => this.setState({ displayErrorMessage: true })
    );
  };

  renderFormAction() {
    const { initialGroup } = this.props;
    const { groupName, productHierarchyItems } = this.state;
    const disabled = !groupName.length || !productHierarchyItems.length;
    const commonProps = {
      style: { marginBottom: svars.spaceMedium },
      gaCategory: 'V2',
      gaLabel: 'From Product Group List',
      type: 'button',
      inputComponent: ButtonAccent,
      labelPosition: 'right',
      disabled,
      onClick: this.onCreateOrUpdateGroup,
    };
    const contextProps = initialGroup
      ? {
          gaAction: 'Update Product Group',
          icon: 'setting',
          content: capitalize(t`modify-group`),
        }
      : {
          gaAction: 'Add Product Group',
          icon: 'plus',
          content: capitalize(t`create-group`),
        };
    return initialGroup ? (
      <AnalyticsAwareButton {...commonProps} {...contextProps} />
    ) : (
      <AnalyticsAwareButton {...commonProps} {...contextProps} />
    );
  }

  render() {
    const {
      onClose,
      open,
      productHierarchiesItems,
      initialGroup,
      relatedViewFacetNames,
      productHierarchiesLoading,
    } = this.props;
    const {
      groupName,
      productHierarchyItems,
      displayErrorMessage,
      hideWarning,
    } = this.state;
    const createOrModify = capitalize(initialGroup ? t`modify` : t`create`);
    return (
      <Modal closeIcon onClose={() => onClose(undefined)} open={open}>
        <Modal.Header
          content={capitalize(
            t`${createOrModify} un groupe de produit(s) / service(s)`
          )}
        />
        <Modal.Content>
          <Grid
            style={{
              padding: `${svars.spaceSmall} ${svars.spaceLarge}`,
            }}
          >
            {relatedViewFacetNames.length && !hideWarning ? (
              <Grid.Row>
                <Grid.Column>
                  <Message warning onDismiss={this.dismissWarning}>
                    <Message.Header>
                      <Trans id="group-create.group-used-in-analysis" />.
                    </Message.Header>
                    <p>
                      <Trans id="group-create.group-used-in-following-analysis" />
                      :
                    </p>
                    <ul>
                      {relatedViewFacetNames.map(({ name }) => (
                        <li key={`wml-${name}`}>{name}</li>
                      ))}
                    </ul>
                    <p>
                      <Trans id="group-create.when-updating-group-analysis-are-updated" />
                    </p>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row as={MediumPaddedSegment}>
              <Grid.Column width={6} verticalAlign="bottom">
                <Header as="h4">
                  <Trans id="product-service-group-name" />
                </Header>
              </Grid.Column>
              <Grid.Column width={10} verticalAlign="bottom">
                <Form>
                  <LimitedTextInput
                    error={displayErrorMessage}
                    // error={
                    //   displayErrorMessage
                    //     ? {
                    //         content: capitalize(t`group-name-already-used`),
                    //         pointing: 'below',
                    //       }
                    //     : null
                    // }
                    style={{
                      fontWeight: svars.fontWeightBold,
                      fontSize: svars.fontSizeXLarge,
                    }}
                    placeholder={capitalize(
                      t`product-service-group-name-placeholder`
                    )}
                    value={groupName}
                    onChange={(e, { value }) =>
                      this.setState({
                        groupName: value,
                        displayErrorMessage: false,
                      })
                    }
                    maxCharacters={svars.hierarchyGroupNameMaxCharacters}
                  />
                </Form>
                {displayErrorMessage ? (
                  <div
                    style={{
                      color: svars.colorDanger,
                      paddingTop: svars.spaceNormal,
                    }}
                  >
                    <Trans id="group-name-already-used" />
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              centered
              as={MediumPaddedSegment}
              style={{ marginTop: 0 }}
            >
              <Grid.Column width={16}>
                <Header as="h4">
                  <Trans id="products-services" />
                  <Header.Subheader>
                    <Trans id="products-services-group-content-description" />
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column width={16} style={{ paddingTop: svars.spaceMedium }}>
                <HierarchicalCheckboxList
                  style={{
                    padding: svars.spaceNormal,
                    height: '45vh',
                    boxShadow: svars.baseBoxShadow,
                    borderRadius: svars.borderRadius,
                  }}
                  items={productHierarchiesItems}
                  onSelectItems={(itemsToAdd) => {
                    this.setState({
                      productHierarchyItems: [
                        ...productHierarchyItems,
                        ...itemsToAdd.map(({ value }) => value),
                      ],
                    });
                  }}
                  onResetItems={() =>
                    this.setState({ productHierarchyItems: [] })
                  }
                  onUnselectItems={(itemsToRemove) => {
                    const values = itemsToRemove.map(({ key }) => key);
                    this.setState({
                      productHierarchyItems: productHierarchyItems.filter(
                        ({ id }) => !values.includes(id)
                      ),
                    });
                  }}
                  isSelectedItem={(item, selectedItems) =>
                    (selectedItems || []).some(
                      (selectedItem) => selectedItem.id === item.key
                    )
                  }
                  selectedItems={productHierarchyItems}
                  placeholder={t`search-a-product-service`}
                  searchable
                  loading={productHierarchiesLoading || !productHierarchyItems}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <ButtonTransparentAccent
            type="submit"
            onClick={() => {
              onClose();
            }}
          >
            <Trans id="cancel" />
          </ButtonTransparentAccent>
          {this.renderFormAction()}
        </Modal.Actions>
      </Modal>
    );
  }
}

ProductHierarchyGroupCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCreateProductHierarchyGroup: PropTypes.func.isRequired,
  onUpdateProductHierarchyGroup: PropTypes.func.isRequired,
  onMaybeFetchProductHierarchies: PropTypes.func.isRequired,
  productHierarchiesItems: PropTypes.arrayOf(
    commonPropTypes.productHierarchyItem
  ).isRequired,
  productHierarchiesLoading: PropTypes.bool.isRequired,
  // If initial group is provided, fill up state with existing group and update it upon
  // CTA click
  initialGroup: commonPropTypes.productHierarchyGroup,
  relatedViewFacetNames: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string })
  ),
};

ProductHierarchyGroupCreateModal.defaultProps = {
  initialGroup: undefined,
  relatedViewFacetNames: [],
};

const productHierarchiesLoadingSelector = createLoadingSelector([
  entitiesActionTypes.FETCH_PRODUCT_HIERARCHIES_REQUEST,
]);

export default connect(
  (state) => ({
    productHierarchies: state.entities.productHierarchies,
    productHierarchiesItems: state.entities.productHierarchiesItems,
    productHierarchiesLoading: productHierarchiesLoadingSelector(state),
  }),
  {
    onCreateProductHierarchyGroup: createProductHierarchyGroup,
    onUpdateProductHierarchyGroup: updateProductHierarchyGroup,
    onMaybeFetchProductHierarchies: maybeFetchProductHierarchies,
  }
)(ProductHierarchyGroupCreateModal);
